import Image from "@Components/Image";
import { AsEasy } from "@Components/pages/common";
import type { EasyStep } from "@Components/pages/common/AsEasy/components/types";
import type { ButterAsEasyAsFields } from "@Components/pages/common/LandingAsEasyAs/types";

export type LandingAsEasyAsProps = {
  content: ButterAsEasyAsFields | null;
  hasBackgroundColor?: boolean;
  isFullWidth?: boolean;
};

const LandingAsEasyAs = ({ content, hasBackgroundColor = true, isFullWidth = true }: LandingAsEasyAsProps) => {
  const {
    cta_text: ctaText,
    cta_theme: ctaTheme,
    cta_url: ctaUrl,
    header,
    id,
    steps,
    show_bullet_steps: showBulletSteps,
    show_border_top: showBorderTop,
  } = content || {};

  const mappedSteps: EasyStep[] = (steps || []).map(({ bullet_icon, copy, icon }, index) => ({
    bulletIcon: bullet_icon ? <Image src={bullet_icon} alt="" width={24} height={24} /> : null,
    copy,
    icon,
    key: `${copy}-${index}`,
  }));

  return (
    <AsEasy
      ctaText={ctaText}
      ctaTheme={ctaTheme?.theme}
      ctaUrl={ctaUrl}
      hasBackgroundColor={hasBackgroundColor}
      id={id}
      isFullWidth={isFullWidth}
      steps={mappedSteps}
      showBorderTop={showBorderTop}
      showBulletSteps={showBulletSteps}
      titleText={header}
    />
  );
};

export default LandingAsEasyAs;
