import { BottomSheet, H4, Paragraph } from "@boxt/design-system";

import Image from "@Components/Image";

import { BottomSheetItem, BottomSheetWrapper, IconWrapperBottomSheet, TextWrapper } from "../../styles";
import type { ButterValuePropBannerFields } from "../../types";

export type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  fields: ButterValuePropBannerFields;
};

const ValuePropBottomSheet = ({ isOpen, setIsOpen, fields }: Props) => {
  return (
    <BottomSheet blocking open={isOpen} setIsOpen={setIsOpen} maxWidth={1100}>
      <BottomSheetWrapper>
        {fields?.value_prop_fields?.map((field, index) => (
          <BottomSheetItem key={index}>
            <IconWrapperBottomSheet>
              <Image src={field.icon} alt="icon" width={44} height={44} />
            </IconWrapperBottomSheet>
            <TextWrapper>
              <H4 boxtTheme="slateDark" align="left">
                {field.title}
              </H4>
            </TextWrapper>
            <Paragraph boxtTheme="slateDark" align="left">
              {field.bottom_sheet_text}
            </Paragraph>
          </BottomSheetItem>
        ))}
      </BottomSheetWrapper>
    </BottomSheet>
  );
};

export default ValuePropBottomSheet;
