"use client";

import styled from "styled-components";
import { breakpoint, Button, includeSpacing, rem } from "@boxt/design-system";

import { BACKGROUND_COLORS } from "@Collections/themeColors";

export const CardContainer = styled.div<{ $backgroundColor: keyof typeof BACKGROUND_COLORS }>`
  background-color: ${({ $backgroundColor }) => BACKGROUND_COLORS[$backgroundColor]};
  ${includeSpacing({ mv: { sm: 4, md: 5 } })};
  padding: 1.5rem 1rem 0 1rem;
  border-radius: ${rem(12)};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  ${breakpoint("md")} {
    border-radius: ${rem(16)};
    padding: 3rem 1rem 0 1rem;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: ${rem(256)};
  height: ${rem(130)};
`;

export const CTAButton = styled(Button)`
  width: 100%;
  margin-bottom: 1.5rem;

  ${breakpoint("md")} {
    width: ${rem(343)};
  }
`;
