import { StyledTile } from "../../styles";
import type { AchievementTileFields } from "../../types";
import AnimatedNumberComponent from "../AnimatedNumberComponent";
import FirstTileWithVan from "../FirstTileWithVan";
import { StyledDiv, StyledGridCol, StyledParagraph, TrustpilotStarsSVG } from "../styles";

type Props = {
  tiles: AchievementTileFields[];
  firstTile?: AchievementTileFields;
  width?: number;
  height?: number;
};

const AchievementTilesColumn = ({ tiles, firstTile, width, height }: Props) => {
  return (
    <StyledGridCol>
      {firstTile && (
        <FirstTileWithVan
          amount={firstTile.amount}
          description={firstTile.description}
          width={width}
          height={height}
          isAnimated={firstTile.tally_animation}
        />
      )}

      {tiles.map(({ amount, description, trustpilot_widget: trustpilotWidget, tally_animation: animation }, i) => (
        <StyledTile $firstTile={false} key={i}>
          <StyledDiv>
            <AnimatedNumberComponent amount={amount} isAnimated={animation} />
            {trustpilotWidget && <TrustpilotStarsSVG />}
          </StyledDiv>
          <StyledParagraph>{description}</StyledParagraph>
        </StyledTile>
      ))}
    </StyledGridCol>
  );
};

export default AchievementTilesColumn;
