import TileItem from "@Components/pages/common/LandingImageTiles/TileItem";
import type { ButterImageTilesFields, ButterTile } from "@Components/pages/common/LandingImageTiles/types";

export type Props = {
  content: ButterImageTilesFields;
};

const LandingImageTilesContent = ({ content }: Props) => {
  const tiles: ButterTile[] = content?.tiles || [];

  return (
    <>
      {tiles.map((tile: ButterTile, index) => (
        <TileItem
          item={tile}
          key={`tile-${index}`}
          bottomSheetContent={{
            whatsIncluded: tile?.bottom_sheet_whats_included || [],
            h2: tile?.bottom_sheet_h2 || "",
            paragraph: tile?.bottom_sheet_paragraph || "",
            infoTitle: tile?.bottom_sheet_info_h5 || "",
            infoParagraph: tile?.bottom_sheet_info_paragraph || "",
            header: tile?.bottom_sheet_header || "",
            bottomLinkText: tile?.bottom_sheet_bottom_link_text || "",
            bottomLinkUrl: tile?.bottom_sheet_bottom_link_url || "",
          }}
        />
      ))}
    </>
  );
};

export default LandingImageTilesContent;
