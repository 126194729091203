import { Grid, H5, Spacing } from "@boxt/design-system";

import createKey from "@Helpers/createKey";
import Image from "@Components/Image";
import type { BulletPoint } from "@Components/pages/common/LandingWhySection/types";
import {
  Container,
  ContentCol,
  ImageCol,
  LogoWrapper,
  StyledBulletPoints,
} from "@Components/pages/common/LandingWhySection/WhySectionDetails/styles";

export interface Props {
  imageSrc: string;
  contentHeader: string;
  bulletPoints: BulletPoint[];
  contentLogo: string;
  bulletPointsIcon: string;
}

const WhySectionDetails = ({ imageSrc, bulletPoints, contentHeader, contentLogo, bulletPointsIcon }: Props) => {
  const formattedBulletPoints: { item: string; key: string }[] = bulletPoints.map(({ text }, i) => ({
    item: text,
    key: `${createKey(text)}-bullet-point-${i}`,
  }));

  return (
    <Grid.Row as={Container}>
      <Grid.Col as={ImageCol} sm={0} md={4}>
        {imageSrc && <Image src={imageSrc} sizes="40vw" layout="fill" alt="" objectFit="contain" />}
      </Grid.Col>
      <Grid.Col as={ContentCol} sm={8} md={4}>
        <LogoWrapper>{contentLogo && <Image src={contentLogo} alt="" width={139} height={56} />}</LogoWrapper>
        <Spacing mb={3} />
        <H5 boxtTheme="slate" bottom={5}>
          {contentHeader}
        </H5>
        <StyledBulletPoints
          boxtTheme="slate"
          items={formattedBulletPoints}
          {...(bulletPointsIcon && { icon: <Image src={bulletPointsIcon} alt="" width={20} height={20} /> })}
        />
      </Grid.Col>
    </Grid.Row>
  );
};

export default WhySectionDetails;
