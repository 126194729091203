import type { ElementType, ReactNode } from "react";
import { useSpring } from "react-spring";
import type { AnimatedComponent } from "@react-spring/web";
import { animated, useInView } from "@react-spring/web";
import { breakpoints } from "@boxt/design-system";

import useMedia from "@Hooks/useMedia";

import { calcOffset, translateYFx } from "./helpers";

const THRESHOLD = 0;

type Props = {
  useScroll: (callback: () => void, deps: [boolean]) => void;
  children: ReactNode;
};

// Makes a wrapped element(s) appear as if it is moving more quickly
// than others in the direction of the scroll. It achieves this
// by tracking scroll distance (offset) for the element and
// moving the element horizontally by 10% (0.1) of this distance on
// every scroll event.
const ParallaxComponent = ({ useScroll, children }: Props) => {
  const [ref, inView] = useInView({ amount: THRESHOLD });
  const [{ offset }, offsetAPI] = useSpring(() => ({ offset: 0 }));

  const currentOffset = offset.get(); // zero until animated element leaves viewport

  /** @see https://github.com/pmndrs/react-spring/issues/1515 */
  const AnimatedDiv: AnimatedComponent<ElementType> = animated.div;
  const isLg = useMedia(`(min-width: ${breakpoints.lg.width})`);

  const initialTop = ref.current && ref.current.getBoundingClientRect().top;

  useScroll(() => {
    if (!inView) return;

    const refRect = ref.current.getBoundingClientRect();

    let newOffset: number;

    // Is element coming back into view from top?
    if (currentOffset > 0) {
      newOffset = calcOffset(0, refRect.bottom, currentOffset);
    } else {
      // Element is still in view or scrolling down
      newOffset = calcOffset(initialTop, refRect.top, currentOffset);
    }

    offsetAPI.set({ offset: newOffset });
  }, [inView]);

  return (
    <AnimatedDiv ref={ref} style={(isLg && { willChange: "transform", transform: offset.to(translateYFx) }) || null}>
      {children}
    </AnimatedDiv>
  );
};

export default ParallaxComponent;
