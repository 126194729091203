import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { breakpoints, Grid } from "@boxt/design-system";

import Image from "@Components/Image";
import useMedia from "@Hooks/useMedia";

import { AnswerChild } from "./AnswerChild";
import { getTableRows } from "./getTableRows";
import { QuestionChild } from "./QuestionChild";
import { BottomLink, BottomParagraph, GridContainer, Heading, ImageCol, RowWrapper, StyledGrid } from "./styles";
import type { TableContent } from "./types";

export type Props = {
  content: TableContent;
  i18nNamespace: string;
  testId?: string;
};

export const ComparisonTable = ({ content, i18nNamespace, testId }: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const isSm = useMedia(`(max-width: ${breakpoints.md.width})`);
  const { questions, groups, tableHeader } = content;
  const rows = getTableRows(questions, groups);

  const imageWidth = isSm ? 120 : 140;
  const imageHeight = isSm ? 70 : 80;

  return (
    <StyledGrid role="table" data-testid={testId}>
      <Grid.Row as={GridContainer}>
        <Grid.Col sm={4} md={8} lg={16}>
          <Grid.Row>
            <Grid.Col sm={4} md={{ span: 6, offset: 1 }} lg={{ span: 14, offset: 1 }}>
              {tableHeader && <Heading>{t("comparison-table.header")}</Heading>}
            </Grid.Col>
          </Grid.Row>
          <Grid.Row as={RowWrapper}>
            <Grid.Col sm={2} md={4} lg={{ span: 6, offset: 2 }} />
            {content.groups.map((group) => (
              <Grid.Col sm={1} md={2} lg={3} role="columnheader" as={ImageCol} key={group.key} $theme={group.theme}>
                <Image src={group.image} alt={group.name} objectFit="contain" width={imageWidth} height={imageHeight} />
              </Grid.Col>
            ))}
          </Grid.Row>

          {rows.map((row, index) => {
            const isLastRow = index === rows.length - 1;
            const hasTooltip = row.values.some((value) => value.answer.tooltipText);
            return (
              <Grid.Row as={RowWrapper} key={row.question.text} role="row">
                <QuestionChild
                  question={row.question}
                  hasTooltip={hasTooltip}
                  isLastRow={isLastRow}
                  i18nNamespace={i18nNamespace}
                  hoverBehaviour={isSm ? "click" : "hover"}
                />
                {row.values.map((value, index) => (
                  <AnswerChild
                    key={`${value.answer.key}-${index}`}
                    answer={value.answer}
                    theme={value.theme}
                    isLastRow={isLastRow}
                    i18nNamespace={i18nNamespace}
                    hoverBehaviour={isSm ? "click" : "hover"}
                  />
                ))}
              </Grid.Row>
            );
          })}
        </Grid.Col>
        <Grid.Row>
          <Grid.Col sm={4} md={{ span: 6, offset: 1 }} lg={{ span: 14, offset: 1 }}>
            <Trans
              i18nKey={`${i18nNamespace}:comparison-table.bottom-text`}
              components={{
                p: <BottomParagraph align="center" top={8} bottom={8} boxtTheme="slate" />,
                link: <BottomLink color="coral" href="/boilers/subscription/how-does-it-compare" />,
              }}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid.Row>
    </StyledGrid>
  );
};
