"use client";

import styled from "styled-components";
import { includeSpacing } from "@boxt/design-system";

export const VideoContainer = styled.div<{ $includeMarginTop: boolean }>`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  ${({ $includeMarginTop }) =>
    includeSpacing({ mh: { sm: 2, md: 4, lg: 0 }, ...{ [$includeMarginTop ? "mv" : "mb"]: { sm: 3, md: 5 } } })}
`;
