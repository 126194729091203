import { first } from "lodash-es";

import type { ButterSpacingLayoutCollectionItem } from "@Collections/types/butter/collectionItem";

import { StyledSpacing } from "./styles";
import type { ButterSpacingFields } from "./types";

type props = {
  fields: ButterSpacingFields;
};

const getSpacing = (spacing: ButterSpacingLayoutCollectionItem) => spacing.value || 0;

const ButterSpacing = ({ fields }: props) => (
  <StyledSpacing
    id={fields.id}
    $sm={getSpacing(fields.sm)}
    $md={getSpacing(fields.md)}
    $lg={getSpacing(fields.lg)}
    $bgColor={first(fields.bg_color)?.name}
  />
);
export default ButterSpacing;
