import { useState } from "react";
import type { FontTheme } from "@boxt/design-system";
import { BottomSheet, H4, Paragraph, SideDrawer, Spacing } from "@boxt/design-system";

import { dangerouslySetInnerHTML } from "@Helpers/dangerouslySetInnerHTML";
import Image from "@Components/Image";
import {
  Container,
  ContentWrapper,
  Cover,
  StyledButton,
  StyledContent,
  TextWrapper,
  Wrapper,
} from "@Components/pages/common/LandingImageTiles/TileItem/styles";
import type { BottomSheetContent, ButterTile } from "@Components/pages/common/LandingImageTiles/types";

import { getDOMParserOptions } from "../../HTMLParser/helpers";

import TileItemBottomSheetContent from "./components/bottomSheetContent";

export type Props = {
  item: ButterTile;
  bottomSheetContent: BottomSheetContent;
  tileId?: string;
  drawerId?: string;
};

const TileItem = ({ item, tileId, drawerId, bottomSheetContent }: Props) => {
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false);

  const textTheme = item?.text_theme?.name || "slate";

  const showMoreInfo = item?.cta_type === "side_drawer" || item?.cta_type === "bottom_sheet" || false;

  const handleClick = () => {
    if (showMoreInfo) {
      setIsMoreInfoOpen(true);
    }
  };

  return (
    <>
      <Container id={tileId}>
        <Wrapper>
          <Cover data-testid="tile-item-cover">
            {item?.background_image && <Image src={item.background_image} alt="" layout="fill" objectFit="cover" />}
          </Cover>
          <ContentWrapper>
            <TextWrapper>
              <Paragraph boxtTheme={textTheme} weight="bold" bottom={5}>
                {item?.h1 || ""}
              </Paragraph>
              <H4 boxtTheme={textTheme} bottom={{ sm: 0, lg: 5 }}>
                {item?.h2 || ""}
              </H4>
              {item?.body && (
                <StyledContent $isContentColorOverriden={false} $textTheme={textTheme}>
                  <>
                    {dangerouslySetInnerHTML(
                      item.body,
                      getDOMParserOptions({
                        contentTheme: textTheme as FontTheme,
                        headingTheme: textTheme as FontTheme,
                      }),
                    )}
                  </>
                </StyledContent>
              )}
            </TextWrapper>
            <Spacing mt={3} />
            {item?.cta_type !== "none" && (
              <StyledButton
                {...(item?.cta_type === "url" && { href: item?.cta_url })}
                boxtTheme={item?.cta_theme?.theme || "dark"}
                skin="primary"
                onClick={handleClick}
              >
                {item?.cta_text}
              </StyledButton>
            )}
          </ContentWrapper>
        </Wrapper>
      </Container>
      {showMoreInfo && item?.cta_type === "side_drawer" && (
        <SideDrawer isOpen={isMoreInfoOpen} setIsOpen={setIsMoreInfoOpen} title={item?.h1 || ""}>
          <TileItemBottomSheetContent id={drawerId} content={bottomSheetContent} dataTestId="tile-item-side-drawer" />
        </SideDrawer>
      )}
      {showMoreInfo && item?.cta_type === "bottom_sheet" && (
        <BottomSheet headerTitle={item?.h1 || ""} setIsOpen={setIsMoreInfoOpen} open={isMoreInfoOpen} maxWidth={700}>
          <TileItemBottomSheetContent id={drawerId} content={bottomSheetContent} dataTestId="tile-item-bottom-sheet" />
        </BottomSheet>
      )}
    </>
  );
};

export default TileItem;
