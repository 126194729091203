import { Grid, H3, Paragraph } from "@boxt/design-system";

import Image from "@Components/Image";
import { ReactComponent as BulletSVG } from "@Images/icons/bullet.svg";

import { BulletWrapper, Card, IconWrapper, ListItem, ListWrapper } from "./styles";
import type { ButterListCardFields } from "./types";

export type Props = {
  fields: ButterListCardFields;
};

const ButterListCard = ({ fields }: Props) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Col sm={4} md={{ span: 6, offset: 1 }} lg={{ span: 8, offset: 4 }}>
          <Card $backgroundColour={fields.background_colour?.name}>
            <H3 align="center">{fields.title}</H3>
            <ListWrapper>
              {fields.bullets.map((bullet, index) => (
                <ListItem key={index} data-testid={`bullet-item-${index}`}>
                  {fields.icon ? (
                    <IconWrapper data-testid={`custom-icon-${index}`}>
                      <Image src={fields.icon} width={22} height={22} alt={fields.icon_alt} />
                    </IconWrapper>
                  ) : (
                    <BulletWrapper data-testid={`default-bullet-${index}`}>
                      <BulletSVG />
                    </BulletWrapper>
                  )}
                  <div>
                    <Paragraph boxtTheme="slate">{bullet.text}</Paragraph>
                  </div>
                </ListItem>
              ))}
            </ListWrapper>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export default ButterListCard;
