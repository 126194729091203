import useTranslation from "next-translate/useTranslation";
import { PopUp } from "@boxt/design-system";

import OwnershipMethod from "./components/OwnershipMethod";
import { Wrapper } from "./styles";
import type { OwnershipMethodDetails, OwnershipMethodType } from "./types";

export type Props = {
  onClickRental: () => void;
  onClickPurchase: () => void;
  onDismiss: () => void;
  isLoadingScreener: boolean;
};

const LifePopup = ({ onClickPurchase, onClickRental, onDismiss, isLoadingScreener }: Props) => {
  const { t } = useTranslation("boilers/index");
  const purchase: OwnershipMethodDetails = t("life-popup.purchase", {}, { returnObjects: true });
  const rental: OwnershipMethodDetails = t("life-popup.rental", {}, { returnObjects: true });

  const ownershipMethods: OwnershipMethodType[] = [
    { ...purchase, onClick: onClickPurchase, testId: "life-popup-purchase", isLoadingScreener },
    { ...rental, onClick: onClickRental, testId: "life-popup-rental", isLoadingScreener },
  ];

  return (
    <PopUp
      onDismiss={onDismiss}
      ariaLabel={{ "aria-label": t("life-popup.title") }}
      hasPadding={false}
      hasCloseButton
      contentSize="xlarge"
      data-testid="life-popup"
    >
      <Wrapper>
        {ownershipMethods.map((method) => (
          <OwnershipMethod key={method.key} method={method} />
        ))}
      </Wrapper>
    </PopUp>
  );
};

export default LifePopup;
