"use client";

import styled from "styled-components";
import { Grid, includeSpacing } from "@boxt/design-system";

export const StyledSmallImagesWrapper = styled(Grid.Col)`
  &:nth-child(3) {
    ${includeSpacing({ mt: { sm: 0, lg: 7 } })};
  }
`;

export const StyledLargeImageWrapper = styled(Grid.Col)`
  &:nth-child(2) {
    ${includeSpacing({ mt: { sm: 1, lg: 6 }, mb: { sm: 1, lg: 0 } })};
  }

  &:nth-child(4) {
    ${includeSpacing({ mt: { sm: 1, lg: 4 }, mb: { sm: 1, lg: 0 } })};
  }
`;
