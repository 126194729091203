import useTranslation from "next-translate/useTranslation";
import { Grid, Paragraph, ToolTip } from "@boxt/design-system";

import { ReactComponent as Info } from "@Images/icons/comparison-tooltip.svg";

import { Question } from "./styles";
import type { Question as QuestionType } from "./types";

export type Props = {
  question: QuestionType;
  isLastRow: boolean;
  i18nNamespace: string;
  hoverBehaviour: "click" | "hover";
  hasTooltip: boolean;
};

export const QuestionChild = ({ question, isLastRow, i18nNamespace, hoverBehaviour, hasTooltip }: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const { text, tooltipText } = question;
  return (
    <Grid.Col
      sm={2}
      md={4}
      lg={{ span: 6, offset: 2 }}
      role="rowheader"
      as={Question}
      $isLastRow={isLastRow}
      $hasTooltip={hasTooltip}
    >
      <Paragraph boxtTheme="slate" weight="bold" align="left">
        {t("comparison-table.question", { question: text })}
      </Paragraph>
      {tooltipText && (
        <ToolTip toolTipText={tooltipText} triggerAction={hoverBehaviour}>
          <Info />
        </ToolTip>
      )}
    </Grid.Col>
  );
};
