import { Grid } from "@boxt/design-system";

import { addSoftHyphens } from "@Helpers/strings";
import Image from "@Components/Image";
import {
  Content,
  CTAButton,
  ImageCol,
  ImageWrapper,
  Subtitle,
} from "@Components/pages/common/ImageText/ImageTextItem/styles";
import type { ButterImageTextCard } from "@Components/pages/common/ImageText/types";

type Props = {
  item: ButterImageTextCard;
};

const ImageTextItem = ({ item: { image, cta_text, title, cta_theme, cta_url, body, position } }: Props) => {
  return (
    <>
      <Grid.Col md={4} sm={8} as={ImageCol} $position={position} data-testid="image-content">
        <ImageWrapper>
          <Image src={image} layout="fill" objectFit="cover" alt={title} />
        </ImageWrapper>
      </Grid.Col>
      <Grid.Col md={4} sm={8}>
        <Subtitle bottom={{ sm: 5, md: 6 }} top={{ sm: 6, md: 0 }}>
          {addSoftHyphens(title)}
        </Subtitle>
        <Content bottom={{ sm: 5, md: 6 }}>{addSoftHyphens(body)}</Content>
        {Boolean(cta_text) && (
          <CTAButton boxtTheme={cta_theme?.theme || "jade"} href={cta_url} data-testid="cta-button">
            {addSoftHyphens(cta_text)}
          </CTAButton>
        )}
      </Grid.Col>
    </>
  );
};

export default ImageTextItem;
