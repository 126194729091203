import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { EventCategory, HitType, useFsFlag } from "@flagship.io/react-sdk";
import { parseCookies } from "nookies";
import useSWR from "swr";
import { breakpoints } from "@boxt/design-system";

import { Products } from "@Collections/products";
import { API_URLS, BOILER_SUBSCRIPTION_URLS, SCREENING_URLS } from "@Collections/routes";
import type { ButterButtonCollectionItem, ButtonSkinItem } from "@Collections/types/butter/collectionItem";
import { Question } from "@StateMachines/Screener";
import { fetcher } from "@DataAccess/local";
import { sendToCs } from "@Lib/contentsquare";
import { actions, category, labels, ReactGAEvent, screenerCategory } from "@Lib/googleAnalytics";
import LifePopup from "@Components/pages/boilers/installation/LifePopup";
import { Postcode } from "@Components/screener";
import validateRegion, { validateAddress } from "@Components/screener/Postcode/dataAccess";
import useBoxtFlagship from "@Hooks/useBoxtFlagship";
import useMedia from "@Hooks/useMedia";
import { initScreenerWithPostcode } from "@Hooks/useScreener";
import { SCREENER_COOKIE_KEY } from "@Pages/api/_next/screeners/_constants";
import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

import type { ButterColorFields } from "src/types/butterColors";

import FinishQuote from "./components/FinishQuote/FinishQuote";
import { Container } from "./styles";
const { publicRuntimeConfig } = getConfig();

export type Props = {
  screenerVariant?: ScreenerVariant;
  startQuoteButtonText?: string;
  finishQuoteButtonText?: string;
  finishQuoteButtonTheme?: ButterButtonCollectionItem;
  finishQuoteButtonSkin?: ButtonSkinItem;
  startQuoteButtonSkin?: ButtonSkinItem;
  startQuoteButtonTheme?: ButterButtonCollectionItem;
  typeformScreenerUrl?: string;
  postcodeTooltipTextTheme?: ButterColorFields;
  postcodeToolTipBackgroundColour?: string;
  postcodeLabel?: string;
  postcodePlaceholderMobile?: string;
  postcodePlaceholderDesktop?: string;
  postcodeButtonText?: string;
  postcodeButtonColour?: string;
  productType?: Products;
};

const handleAnalyticEvent = (action: string) => {
  ReactGAEvent({
    category: category.landing,
    action,
    label: labels.hero,
  });
};

type ScreenerState = { isScreenerComplete: boolean; isScreenerStarted: boolean };

const ScreenerPostcodeEntry = ({
  finishQuoteButtonSkin,
  finishQuoteButtonText,
  finishQuoteButtonTheme,
  screenerVariant,
  startQuoteButtonSkin,
  startQuoteButtonText,
  startQuoteButtonTheme,
  postcodeTooltipTextTheme,
  postcodeToolTipBackgroundColour,
  postcodeLabel,
  postcodePlaceholderMobile,
  postcodePlaceholderDesktop,
  postcodeButtonText,
  postcodeButtonColour,
  typeformScreenerUrl,
  productType,
}: Props) => {
  const [isLoadingScreener, setIsLoadingScreener] = useState(false);
  const postcodeInput = useRef<HTMLInputElement | null>(null);
  const isLifeModalFlagEnabled = useFsFlag("nextjs-blp-life-modal", false).getValue();
  const isBoilerInstallationExperimentScreenerActive = useFsFlag("exp_screener", false).getValue();
  const { push, query } = useRouter();
  const [variant, setVariant] = useState<ScreenerVariant | undefined>(screenerVariant);
  const isExperimentalScreener =
    [ScreenerVariant.BoilerInstallation, ScreenerVariant.BoilerSubscription].includes(
      screenerVariant as ScreenerVariant,
    ) && isBoilerInstallationExperimentScreenerActive;

  useEffect(() => {
    if (isExperimentalScreener) {
      setVariant(ScreenerVariant.BoilerInstallationExperiment);
    } else {
      setVariant(screenerVariant);
    }
  }, [isExperimentalScreener, setVariant, isBoilerInstallationExperimentScreenerActive, screenerVariant]);

  const { data, error, mutate, isValidating } = useSWR<ScreenerState>(
    `${API_URLS.SCREENING_STATUS}?screenerVariant=${variant}`,
    (url) => fetcher(url),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const [isLifeModalOpen, setIsLifeModalOpen] = useState(false);
  const [postcode, setPostcode] = useState<string | null>(null);
  const { fireFlagshipEvent } = useBoxtFlagship();

  const isMobile = useMedia(`(max-width: ${breakpoints.md.width})`);

  const skeletonHeight = isMobile ? 60 : 92;

  const isLoading = (!data && !error) || isValidating;

  const isEdfUrl = query?.utm_source?.includes("edf");

  const handleOnStartQuote = async () => {
    await mutate(
      (prevData: ScreenerState) => ({
        ...prevData,
        isScreenerStarted: false,
      }),
      { revalidate: false },
    );

    postcodeInput.current?.focus();
  };

  const handleClickForFlagship = (name: string) =>
    fireFlagshipEvent({
      type: HitType.EVENT,
      category: EventCategory.ACTION_TRACKING,
      action: name,
    });

  const handleOnFinishQuote = () => {
    setIsLoadingScreener(true);

    if (data?.isScreenerComplete) {
      const cookies = parseCookies();
      const screeningId = cookies[`${SCREENER_COOKIE_KEY}:${variant}`];

      push({
        pathname: SCREENING_URLS[variant as ScreenerVariant].complete.replace("[screeningId]", screeningId),
        query,
      });
    } else {
      goToScreener();
    }
  };

  const startScreener = async (screenerPostcode?: string) => {
    await initScreenerWithPostcode(variant as ScreenerVariant, screenerPostcode ?? postcode);
    sendToCs("ABT_AB_FS_exp_screener", isExperimentalScreener ? "New variation" : "Original");
    goToScreener();
  };

  const handleOnSubmit = async (values: { postcode: string }) => {
    handleAnalyticEvent(actions.postcodeGetStarted);
    ReactGAEvent({
      category: screenerCategory?.[variant as ScreenerVariant],
      action: actions.postcode,
      label: values.postcode,
    });

    if (typeformScreenerUrl) {
      push({ pathname: typeformScreenerUrl });
    } else {
      const postcode = values[Question.Postcode];

      if (publicRuntimeConfig.VALIDATE_POSTCODE === undefined || publicRuntimeConfig.VALIDATE_POSTCODE === "true") {
        await validateAddress(postcode);
      }

      await validateRegion(postcode, productType);

      if (productType === Products.Boiler && !isEdfUrl) {
        sendToCs("AB_ABT_FS_nextjs-blp-life-modal", isLifeModalFlagEnabled ? "New variation" : "Original");
      }

      if (isLifeModalFlagEnabled && productType === Products.Boiler && !isEdfUrl) {
        setIsLifeModalOpen(true);
        setPostcode(postcode);
        return;
      }
      await startScreener(postcode);
    }
  };

  const handleOnClickRental = () => {
    setIsLoadingScreener(true);

    handleClickForFlagship("Modal life cta click");

    push({
      pathname: BOILER_SUBSCRIPTION_URLS.LANDING,
      query: {
        ...query,
        postcode,
      },
    });
  };

  const handleOnClickPurchase = async () => {
    setIsLoadingScreener(true);

    if (isLifeModalFlagEnabled) {
      handleClickForFlagship("Modal install cta click");
    }

    await startScreener();
  };

  const goToScreener = () => {
    delete query.path;

    push({ pathname: SCREENING_URLS[variant as ScreenerVariant].start, query });
  };

  return (
    <>
      <Container>
        {isLoading ? (
          <Skeleton height={skeletonHeight} containerTestId="skeleton-loader" />
        ) : (
          <>
            {data?.isScreenerStarted ? (
              <FinishQuote
                onFinishQuote={handleOnFinishQuote}
                onStartQuote={handleOnStartQuote}
                startQuoteButtonText={startQuoteButtonText}
                finishQuoteButtonText={finishQuoteButtonText}
                finishQuoteButtonSkin={finishQuoteButtonSkin}
                finishQuoteButtonTheme={finishQuoteButtonTheme}
                startQuoteButtonSkin={startQuoteButtonSkin}
                startQuoteButtonTheme={startQuoteButtonTheme}
                isLoading={isLoadingScreener}
              />
            ) : (
              <Postcode
                tooltipTextTheme={postcodeTooltipTextTheme?.name}
                tooltipBgColor={postcodeToolTipBackgroundColour}
                label={postcodeLabel}
                placeholder={{
                  sm: postcodePlaceholderMobile,
                  md: postcodePlaceholderDesktop,
                }}
                onFocus={() => handleAnalyticEvent(actions.postcodeFocus)}
                submitText={postcodeButtonText}
                onSubmit={handleOnSubmit}
                buttonColor={postcodeButtonColour}
                productType={productType}
                ref={postcodeInput}
              />
            )}
          </>
        )}
      </Container>
      {isLifeModalOpen && (
        <LifePopup
          onClickRental={handleOnClickRental}
          onClickPurchase={handleOnClickPurchase}
          onDismiss={() => setIsLifeModalOpen(false)}
          isLoadingScreener={isLoadingScreener}
        />
      )}
    </>
  );
};

export default ScreenerPostcodeEntry;
