import type { KeenSliderOptions } from "keen-slider/react";
import { breakpoints } from "@boxt/design-system";

import {
  LG_MAX_CARDS_VISIBLE,
  LG_SLIDER_PADDING,
  MD_MAX_CARDS_VISIBLE,
  SLIDER_PADDING,
  SM_MAX_CARDS_VISIBLE,
} from "./constants";

export const getConfig = (): KeenSliderOptions => ({
  breakpoints: {
    [`(min-width: ${breakpoints.sm.width})`]: {
      slides: {
        perView: SM_MAX_CARDS_VISIBLE,
        spacing: SLIDER_PADDING,
        origin: "center",
      },
      loop: true,
      initial: 0,
    },
    [`(min-width: ${breakpoints.md.width})`]: {
      slides: {
        perView: MD_MAX_CARDS_VISIBLE,
        spacing: SLIDER_PADDING,
      },
      loop: true,
      initial: 0,
    },
    [`(min-width: ${breakpoints.lg.width})`]: {
      slides: {
        perView: MD_MAX_CARDS_VISIBLE,
        spacing: LG_SLIDER_PADDING,
      },
      loop: true,
      initial: 0,
    },
    [`(min-width: ${breakpoints.xlg.width})`]: {
      slides: {
        perView: LG_MAX_CARDS_VISIBLE,
        spacing: LG_SLIDER_PADDING,
      },
      loop: true,
      initial: 0,
    },
  },
});
