import { useState } from "react";
import type { KeenSliderOptions } from "keen-slider";
import { Grid, H3, Paragraph } from "@boxt/design-system";

import Image from "@Components/Image";
import Slider from "@Components/pages/common/Slider";
import { SliderRow, StyledRow } from "@Components/pages/productType/yourOptions/productDetails/DetailsCard/styles";

import {
  InstallationGuideCol,
  InstallationGuideImage,
  InstallationGuideText,
  SlideCounter,
  SlideWrapper,
  TextCol,
  TextRow,
  WrapperStyle,
} from "./styles";
import type { ButterInstallationGuideSlider } from "./types";

type Props = {
  fields: ButterInstallationGuideSlider;
};

const InstallationGuideSlider = ({ fields }: Props) => {
  const { slides, title, id } = fields;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const sliderOptions: KeenSliderOptions = {
    loop: true,
    slides: {
      spacing: 30,
    },
  };

  return (
    <div id={id}>
      <TextRow>
        <TextCol>
          <H3 weight="regular">{title}</H3>
        </TextCol>
      </TextRow>
      <SliderRow>
        <InstallationGuideCol>
          <Slider
            arrowTheme={{ color: "lighter", position: "inside", size: "small" }}
            showArrows
            wrapperStyle={WrapperStyle}
            duration={0}
            includeSpacing={false}
            initiallyLoop={false}
            sliderOptions={sliderOptions}
            showLeftArrow
            showDotsController={false}
            onSlideChange={(index: number) => setCurrentImageIndex(index)}
          >
            {slides.map((slide, i) => (
              <SlideWrapper key={`${"id"}-asset-${i}`} className={"keen-slider__slide"}>
                <InstallationGuideImage>
                  <Image src={slide.image} alt="product-image" layout="fill" objectFit="contain" />
                </InstallationGuideImage>
                <InstallationGuideText>
                  <Paragraph boxtTheme="dark">{slide.image_text}</Paragraph>
                </InstallationGuideText>
              </SlideWrapper>
            ))}
          </Slider>
        </InstallationGuideCol>
      </SliderRow>
      <StyledRow>
        <Grid.Col lg={{ span: 4, offset: 6 }}>
          <SlideCounter>
            {currentImageIndex + 1}/{slides.length}
          </SlideCounter>
        </Grid.Col>
      </StyledRow>
    </div>
  );
};

export default InstallationGuideSlider;
