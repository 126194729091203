import type { RefObject } from "react";
import { isEmpty } from "lodash-es";
import { H1, H2, Paragraph, Spacing } from "@boxt/design-system";

import type {
  ButterButtonCollectionItem,
  ButterProductTypeCollection,
  ButterScreenerVariantCollection,
  ButtonSkinItem,
} from "@Collections/types/butter/collectionItem";
import Image from "@Components/Image";
import type { ButterProductTile } from "@Components/pages/common/LandingProductTiles/types";

import type {
  ButterLandingHeroCallToActionButton,
  ButterLandingHeroScrollButton,
  ButterLandingHeroUspBadge,
  ButterLandingHeroUspBulletPoint,
} from "../../types";
import CtaButtons from "../CtaButtons";
import ScreenerPostcodeEntry from "../ScreenerPostcodeEntry";
import UspBadges from "../UspBadges";
import UspBulletPoints from "../UspBulletPoints";

import type { ButterColorFields } from "src/types/butterColors";

import { CardContainer, CardContent, CardThumbnail, StyledSubheading, StyledTitle } from "./styles";

export type Props = {
  backgroundColor?: string;
  callToActionButtons?: ButterLandingHeroCallToActionButton[];
  cardId?: string;
  finishQuoteButtonSkin?: ButtonSkinItem;
  finishQuoteButtonText?: string;
  finishQuoteButtonTheme?: ButterButtonCollectionItem;
  heading?: string;
  headingTheme?: ButterColorFields;
  label?: string;
  labelTheme?: ButterColorFields;
  postcodeButtonColour?: string;
  postcodeButtonText?: string;
  postcodeLabel?: string;
  postcodePlaceholderDesktop?: string;
  postcodePlaceholderMobile?: string;
  postcodeToolTipBackgroundColour?: string;
  postcodeTooltipTextTheme?: ButterColorFields;
  productType?: ButterProductTypeCollection;
  screenerVariant?: ButterScreenerVariantCollection;
  startQuoteButtonSkin?: ButtonSkinItem;
  startQuoteButtonText?: string;
  startQuoteButtonTheme?: ButterButtonCollectionItem;
  subheading?: string;
  subheadingTheme?: ButterColorFields;
  thumbnail?: string;
  thumbnailAltText?: string;
  typeformScreenerUrl?: string;
  uspBadges?: ButterLandingHeroUspBadge[];
  uspBulletPoints?: ButterLandingHeroUspBulletPoint[];
  isCardLeftAligned?: boolean;
  scrollTargetRef?: RefObject<HTMLDivElement | null>;
  scrollButton?: ButterLandingHeroScrollButton[];
  heroVariant?: string;
  bottomSheetTiles?: ButterProductTile[];
};

const Card = ({
  backgroundColor,
  callToActionButtons = [],
  cardId,
  finishQuoteButtonSkin,
  finishQuoteButtonText,
  finishQuoteButtonTheme,
  heading,
  headingTheme,
  label,
  labelTheme,
  postcodeButtonColour,
  postcodeButtonText,
  postcodeLabel,
  postcodePlaceholderDesktop,
  postcodePlaceholderMobile,
  postcodeToolTipBackgroundColour,
  postcodeTooltipTextTheme,
  productType,
  screenerVariant,
  startQuoteButtonSkin,
  startQuoteButtonText,
  startQuoteButtonTheme,
  subheading,
  subheadingTheme,
  thumbnail,
  thumbnailAltText,
  typeformScreenerUrl,
  uspBadges = [],
  uspBulletPoints = [],
  isCardLeftAligned,
  scrollTargetRef,
  scrollButton,
  heroVariant,
  bottomSheetTiles,
}: Props) => {
  const hasUspBulletPoints = uspBulletPoints.length > 0;
  const hasUspBadges = uspBadges.length > 0;
  const hasCallToActionButtons = callToActionButtons.length > 0 && isEmpty(screenerVariant);
  const hasScreenerPostcode = !isEmpty(screenerVariant) || !isEmpty(typeformScreenerUrl);
  const HeadingTag = isEmpty(label) ? H1 : H2;
  return (
    <CardContainer
      $backgroundColor={backgroundColor}
      data-testid="landing-hero-card"
      id={cardId}
      $isCardLeftAligned={isCardLeftAligned}
    >
      <CardContent>
        {Boolean(thumbnail) && (
          <CardThumbnail>
            <Image layout="fill" src={thumbnail as string} alt={thumbnailAltText} />
          </CardThumbnail>
        )}
        {Boolean(label) && (
          <Paragraph
            size="small"
            as={StyledTitle}
            boxtTheme={isEmpty(labelTheme) ? "slateDark" : labelTheme?.name}
            bottom={5}
          >
            {label}
          </Paragraph>
        )}
        <HeadingTag boxtTheme={isEmpty(headingTheme) ? "slateDark" : headingTheme?.name} bottom={5}>
          {heading}
        </HeadingTag>
        <StyledSubheading
          size="medium"
          boxtTheme={isEmpty(subheadingTheme) ? "slateDark" : subheadingTheme?.name}
          bottom={5}
        >
          {subheading}
        </StyledSubheading>
        {hasScreenerPostcode && (
          <Spacing mb={3} mt={1}>
            <ScreenerPostcodeEntry
              screenerVariant={screenerVariant?.slug}
              startQuoteButtonText={startQuoteButtonText}
              finishQuoteButtonText={finishQuoteButtonText}
              startQuoteButtonSkin={startQuoteButtonSkin}
              finishQuoteButtonSkin={finishQuoteButtonSkin}
              startQuoteButtonTheme={startQuoteButtonTheme}
              finishQuoteButtonTheme={finishQuoteButtonTheme}
              typeformScreenerUrl={typeformScreenerUrl}
              postcodeTooltipTextTheme={postcodeTooltipTextTheme}
              postcodeToolTipBackgroundColour={postcodeToolTipBackgroundColour}
              postcodeLabel={postcodeLabel}
              postcodePlaceholderMobile={postcodePlaceholderMobile}
              postcodePlaceholderDesktop={postcodePlaceholderDesktop}
              postcodeButtonText={postcodeButtonText}
              postcodeButtonColour={postcodeButtonColour}
              productType={productType?.product_type}
            />
          </Spacing>
        )}

        {(hasCallToActionButtons || scrollButton?.[0]) && (
          <Spacing mb={3} mt={1} id="call-to">
            <CtaButtons
              callToActionButtons={callToActionButtons}
              scrollTargetRef={scrollTargetRef}
              scrollButton={scrollButton}
              heroVariant={heroVariant}
              bottomSheetTiles={bottomSheetTiles}
            />
          </Spacing>
        )}
        {hasUspBulletPoints && (
          <Spacing mb={hasUspBadges ? 2 : 0}>
            <UspBulletPoints uspBulletPoints={uspBulletPoints} />
          </Spacing>
        )}
        {hasUspBadges && <UspBadges uspBadges={uspBadges} />}
      </CardContent>
    </CardContainer>
  );
};

export default Card;
