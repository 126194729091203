import BoxtLife from "./assets/boxt-life.png";
import BritishGas from "./assets/british-gas.png";
import type { ComparisonGroup, Question, TableContent } from "./types";

const tableHeader = "How does BOXT Life subscription compare to traditional boiler ownership over 10 years?";

const questions: Question[] = [
  { key: "rating", text: "Brand new A-rated boiler + cover" },
  { key: "score", text: "Trustpilot score" },
  { key: "priceRises", text: "No annual price rises" },
  {
    key: "annualService",
    text: "Annual service",
  },
  {
    key: "repairsCovered",
    text: "Repairs over £1000 covered",
  },
  {
    key: "calloutCharges",
    text: "No callout charges included",
  },
  {
    key: "costReplacement",
    text: "No cost replacement",
    tooltipText: "If unable to repair your boiler",
  },
  {
    key: "noDeposit",
    text: "No deposit required",
  },
  {
    key: "unexpectedCosts",
    text: "Not having to worry about unexpected boiler costs ",
  },
  {
    key: "cost10Years",
    text: "Cost over 10 years",
  },
];

const groups: ComparisonGroup[] = [
  {
    key: "boxtLife",
    theme: "acid",
    image: BoxtLife,
    name: "Boxt Life",
    answers: [
      { key: "rating", text: "From £33.96p/m" },
      {
        key: "score",
        text: "4.9",
      },
      {
        key: "priceRises",
        iconName: "tick",
      },
      {
        key: "annualService",
        iconName: "tick",
      },
      {
        key: "repairsCovered",
        iconName: "tick",
      },
      {
        key: "calloutCharges",
        iconName: "tick",
      },
      {
        key: "costReplacement",
        iconName: "tick",
      },
      {
        key: "noDeposit",
        iconName: "tick",
      },
      {
        key: "unexpectedCosts",
        text: "Priceless",
      },
      {
        key: "cost10Years",
        text: "£4042.80",
      },
    ],
  },
  {
    key: "britishGas",
    theme: "slate",
    image: BritishGas,
    name: "British Gas",
    answers: [
      { key: "rating", text: "From £2,534 + £31.56 p/m" },
      {
        key: "score",
        text: "4.1",
      },
      {
        key: "priceRises",
        iconName: "cross",
      },
      {
        key: "annualService",
        iconName: "tick",
      },
      {
        key: "repairsCovered",
        iconName: "cross",
      },
      {
        key: "calloutCharges",
        iconName: "tick",
      },
      {
        key: "costReplacement",
        iconName: "cross",
      },
      {
        key: "noDeposit",
        iconName: "tick",
      },
      {
        key: "unexpectedCosts",
        iconName: "cross",
      },
      {
        key: "cost10Years",
        text: "£6321.20",
        tooltipText: "This is without any price rises, which will likely occur",
      },
    ],
  },
];

export const tableContent: TableContent = {
  questions,
  groups,
  tableHeader,
};
