import { useState } from "react";
import type { KeenSliderOptions } from "keen-slider";
import { breakpoints } from "@boxt/design-system";

import Image from "@Components/Image";
import Slider from "@Components/pages/common/Slider";

import type { ButterLandingHeroForegroundImage } from "../../types";

import { DesktopImage, MobileImage, SlideWrapper, TabletImage, WrapperStyle } from "./styles";

export type Props = {
  images: ButterLandingHeroForegroundImage[];
  isForegroundImageMaxWidth?: boolean;
};

const ForegroundImage = ({ images, isForegroundImageMaxWidth }: Props) => {
  const [opacities, setOpacities] = useState<number[]>([]);

  const sliderConfig: KeenSliderOptions = {
    defaultAnimation: {
      duration: 1500,
    },
    detailsChanged: (slider) => {
      const newOpacities = slider.track.details.slides.map((slide) => slide.portion);
      setOpacities(newOpacities);
    },
    breakpoints: {
      [`(max-width: ${breakpoints.sm.width})`]: {
        slides: {
          perView: 1,
          spacing: 0,
        },
        loop: images.length > 1,
        initial: 0,
      },
      [`(min-width: ${breakpoints.sm.width})`]: {
        slides: {
          perView: 1,
          spacing: 0,
        },
        loop: images.length > 1,
        initial: 0,
      },
    },
    renderMode: "custom",
    drag: false,
  };

  return (
    <Slider wrapperStyle={WrapperStyle} includeSpacing={false} sliderOptions={sliderConfig} showDotsController={false}>
      {images.map((image, index) => (
        <SlideWrapper
          key={`image-${index}`}
          className={`keen-slider__slide slide-${index}`}
          $opacity={opacities[index]}
          $isForegroundImageMaxWidth={isForegroundImageMaxWidth}
        >
          <MobileImage>
            <Image src={image.mobile} width={375} height={255} layout="responsive" alt={image.alt_text} />
          </MobileImage>
          <TabletImage>
            <Image src={image.tablet} width={768} height={518} layout="responsive" alt={image.alt_text} />
          </TabletImage>
          <DesktopImage $isForegroundImageMaxWidth={isForegroundImageMaxWidth} data-testid="desktop-image">
            <Image src={image.desktop} layout="fill" objectFit="cover" alt={image.alt_text} />
          </DesktopImage>
        </SlideWrapper>
      ))}
    </Slider>
  );
};

export default ForegroundImage;
