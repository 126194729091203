import Image from "@Components/Image";

import { ImageContainer, LogoListContainer } from "./styles";

export type Logo = { altText: string; image: string; link?: string; logoHeight: number; logoWidth: number };

export type Props = {
  dataTestId?: string;
  logos: Logo[];
};

const LogoList = ({ dataTestId, logos }: Props) => {
  const renderImage = (altText, src, logoHeight, logoWidth) => (
    <ImageContainer key={src}>
      <Image alt={altText} src={src} height={logoHeight} width={logoWidth} />
    </ImageContainer>
  );

  return (
    <LogoListContainer data-testid={dataTestId}>
      {logos.map(({ altText, image: src, link, logoHeight, logoWidth }) =>
        link ? (
          <a href={link} target="_blank" key={src} rel="noreferrer">
            {renderImage(altText, src, logoHeight, logoWidth)}
          </a>
        ) : (
          renderImage(altText, src, logoHeight, logoWidth)
        ),
      )}
    </LogoListContainer>
  );
};

export default LogoList;
