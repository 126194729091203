import { type RefObject, useEffect } from "react";
import Lottie from "react-lottie-player";
import { useFsFlag } from "@flagship.io/react-sdk";

import { sendToCs } from "@Lib/contentsquare";
import lottieJson from "@Components/pages/common/LandingHero/scroll-down.json";

import BackgroundAnimation from "./components/BackgroundAnimation";
import BackgroundImage from "./components/BackgroundImage";
import BackgroundVideo from "./components/BackgroundVideo";
import Card from "./components/Card";
import ForegroundImages from "./components/ForegroundImages";
import TvAdvertBadge from "./components/TvAdvertBadge";
import { ArrowButton, ArrowWrapper, Container, ForegroundImageContainer, InnerContainer, StyledGrid } from "./styles";
import type { ButterLandingHeroFields } from "./types";

export type Props = {
  fields: ButterLandingHeroFields;
  scrollTargetRef?: RefObject<HTMLDivElement | null>;
  hasArrowDown?: boolean;
};

const ButterLandingHero = ({ fields, scrollTargetRef, hasArrowDown = false }: Props) => {
  const hasForegroundImage = fields.foreground_images ? fields.foreground_images?.length > 0 : false;
  const hasBackgroundImage =
    Boolean(fields.background_image_desktop) &&
    Boolean(fields.background_image_tablet) &&
    Boolean(fields.background_image_mobile);
  const hasBackgroundAnimation = Boolean(fields.background_animation);
  const hasBadge = Boolean(fields.badge);
  const hasTvAdvertBadge = Boolean(fields.advert_badge_video_id);
  const isForegroundImageMaxWidth = Boolean(fields.max_width_foreground_image);

  const isLandingHeroVideoEnabled = useFsFlag("homepage-hero-video", false).getValue();
  const hasBackgroundVideo =
    Boolean(fields.background_video) && Boolean(fields.background_video_mobile) && isLandingHeroVideoEnabled;

  useEffect(() => {
    sendToCs("AB_ABT_FS_homepage_hero_video", hasBackgroundVideo ? "New variation" : "Original");
  }, [hasBackgroundVideo]);

  const scrollToTrustpilot = () => {
    const element = document.getElementById("trustpilot");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container
      $backgroundColor={fields.background_colour}
      $gradientType={fields.background_gradient}
      $gradientDegree={fields.background_linear_gradient_degree}
      $gradients={fields.background_gradients}
      $gradientCenterXAxis={fields.background_gradient_center_x_axis}
      $gradientCenterYAxis={fields.background_gradient_center_y_axis}
      data-testid="landing-hero-container"
      id={fields.id}
    >
      {hasBackgroundImage ? (
        <BackgroundImage
          backgroundImage={{
            desktop: fields.background_image_desktop,
            tablet: fields.background_image_tablet,
            mobile: fields.background_image_mobile,
          }}
        />
      ) : null}
      {hasBackgroundAnimation ? <BackgroundAnimation animation={fields.background_animation} /> : null}
      {isForegroundImageMaxWidth && !hasBackgroundVideo ? (
        <ForegroundImages
          hasForegroundImage={hasForegroundImage}
          isForegroundImageMaxWidth={isForegroundImageMaxWidth}
          fields={fields}
          hasBadge={hasBadge}
        />
      ) : null}
      {hasBackgroundVideo ? (
        <BackgroundVideo
          backgroundPoster={fields.background_video_poster}
          backgroundVideo={fields.background_video}
          isVideoLooping={fields.background_video_loop}
          mobileBackgroundPoster={fields.background_video_mobile_poster}
          mobileBackgroundVideo={fields.background_video_mobile}
          videoDescription={fields.background_video_description}
        />
      ) : null}
      <InnerContainer>
        {!isForegroundImageMaxWidth ? (
          <ForegroundImageContainer $hasForegroundImage={hasForegroundImage} id={fields?.carousel_id}>
            <ForegroundImages
              hasForegroundImage={hasForegroundImage}
              isForegroundImageMaxWidth={isForegroundImageMaxWidth}
              fields={fields}
              hasBadge={hasBadge}
            />
          </ForegroundImageContainer>
        ) : null}
        {hasTvAdvertBadge ? (
          <TvAdvertBadge
            advertBadgeVideoId={fields.advert_badge_video_id as string}
            advertBadgeText={fields.advert_badge_text}
          />
        ) : null}
        <StyledGrid>
          <Card
            backgroundColor={fields.card_background_colour}
            callToActionButtons={fields.call_to_action_buttons}
            finishQuoteButtonSkin={fields.finish_quote_button_skin}
            finishQuoteButtonText={fields.finish_quote_button_text}
            finishQuoteButtonTheme={fields.finish_quote_button_theme}
            heading={fields.card_heading}
            headingTheme={fields.card_heading_colour}
            label={fields.card_label}
            labelTheme={fields.card_label_colour}
            postcodeButtonColour={fields.postcode_button_colour}
            postcodeButtonText={fields.postcode_button_text}
            postcodeLabel={fields.postcode_label}
            postcodePlaceholderDesktop={fields.postcode_placeholder_desktop}
            postcodePlaceholderMobile={fields.postcode_placeholder_mobile}
            postcodeToolTipBackgroundColour={fields.postcode_tooltip_background_colour}
            postcodeTooltipTextTheme={fields.postcode_tooltip_theme}
            screenerVariant={fields.product_screening_variant}
            startQuoteButtonSkin={fields.start_quote_button_skin}
            startQuoteButtonText={fields.start_quote_button_text}
            startQuoteButtonTheme={fields.start_quote_button_theme}
            subheading={fields.card_subheading}
            subheadingTheme={fields.card_subheading_colour}
            thumbnail={fields.card_thumbnail}
            thumbnailAltText={fields.card_thumbnail_alt_text}
            typeformScreenerUrl={fields.typeform_screener_url}
            uspBadges={fields.usp_badges}
            uspBulletPoints={fields.usp_bullet_points}
            productType={fields.product_type}
            cardId={fields.card_id}
            isCardLeftAligned={fields.left_aligned_card}
            scrollTargetRef={scrollTargetRef}
            scrollButton={fields.scroll_button}
            heroVariant={fields.hero_variant}
            bottomSheetTiles={fields.get_a_quote_bottom_sheet_tiles}
          />
        </StyledGrid>
      </InnerContainer>
      {hasArrowDown ? (
        <ArrowWrapper>
          <ArrowButton onClick={scrollToTrustpilot} data-testid="arrow-down">
            <Lottie loop animationData={lottieJson} play style={{ width: 40, height: 40 }} />
          </ArrowButton>
        </ArrowWrapper>
      ) : null}
    </Container>
  );
};

export default ButterLandingHero;
