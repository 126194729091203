import styled from "styled-components";
import type { FontTheme } from "@boxt/design-system";
import { breakpoint, colors, Paragraph, rem } from "@boxt/design-system";

import withCustomWrapper from "@Helpers/withCustomWrapper";

export const CardContainer = styled.div<{
  $backgroundColor?: string;
  $isCardLeftAligned?: boolean;
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor || colors.white};
  border-radius: 16px;
  padding: ${rem(24)};

  ${breakpoint("md")} {
    padding: ${rem(32)};
  }

  ${breakpoint("lg")} {
    padding: ${rem(48)};
    border-radius: 24px;
    min-height: ${rem(400)};
    width: ${rem(584)};
    margin-right: ${({ $isCardLeftAligned }) => ($isCardLeftAligned ? "auto" : "0")};
    margin-left: ${({ $isCardLeftAligned }) => ($isCardLeftAligned ? "0" : "auto")};
  }
`;

export const CardContent = styled.div`
  text-wrap: balance;
  position: relative;

  ${breakpoint("md")} {
    max-width: ${rem(454)};
    margin: 0 auto;
  }

  ${breakpoint("lg")} {
    max-width: 100%;
  }
`;

export const StyledTitle = styled.h1`
  text-transform: uppercase;
`;

export const StyledSubheading = withCustomWrapper(
  Paragraph,
  ({ children, boxtTheme }: { children: string; boxtTheme: FontTheme }) => (
    <Paragraph size="medium" boxtTheme={boxtTheme} as="strong" weight="bold" key={children}>
      {children}
    </Paragraph>
  ),
);

export const CardThumbnail = styled.div`
  position: absolute;
  width: ${rem(48)};
  height: ${rem(48)};
  top: ${rem(-16)};
  right: 0;

  ${breakpoint("md")} {
    width: ${rem(72)};
    height: ${rem(72)};
    top: ${rem(-24)};
  }

  ${breakpoint("lg")} {
    top: ${rem(-32)};
  }
`;
