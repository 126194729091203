import styled from "styled-components";
import { breakpoint } from "@boxt/design-system";

export const Container = styled.div`
  ${breakpoint("lg")} {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;

export const MobileVideoContainer = styled.div`
  position: relative;

  ${breakpoint("lg")} {
    display: none;
  }
`;

export const DesktopVideoContainer = styled.div`
  display: none;

  ${breakpoint("lg")} {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const MobileVideoAspectRatio = styled.div`
  padding-top: 70%;

  ${breakpoint("md")} {
    padding-top: 50%;
  }
`;
