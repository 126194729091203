import { Grid, Spacing } from "@boxt/design-system";

import { addSoftHyphens } from "@Helpers/strings";
import ImageTextItem from "@Components/pages/common/ImageText/ImageTextItem";
import { ImageTextRow, ImageTextWrapper, Title } from "@Components/pages/common/ImageText/styles";
import type { ButterImageTextCard, ButterImageTextFields } from "@Components/pages/common/ImageText/types";

type Props = {
  fields: ButterImageTextFields;
};

const ImageText = ({ fields }: Props) => (
  <Spacing mv={{ md: 5, sm: 4 }}>
    {Boolean(fields.title) && <Title data-testid="image-text-title">{addSoftHyphens(fields.title)}</Title>}
    <Grid as={ImageTextWrapper}>
      {fields.cards.map((item: ButterImageTextCard, index) => (
        <Grid.Row key={`image-text-${index}`} as={ImageTextRow}>
          <ImageTextItem item={item} />
        </Grid.Row>
      ))}
    </Grid>
  </Spacing>
);

export default ImageText;
