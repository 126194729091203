"use client";

import styled from "styled-components";
import { breakpoint, rem } from "@boxt/design-system";

export const ButtonsWrapper = styled.div<{ $showComparisonTable?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: ${({ $showComparisonTable }) => ($showComparisonTable ? `0 ${rem(48)}` : 0)};

  button,
  a {
    width: 100%;
  }

  ${breakpoint("md")} {
    width: unset;
  }
`;
