import { memo, useCallback, useEffect, useRef, useState } from "react";
import getConfig from "next/config";
import type { Interpolation } from "styled-components";
import { debounce } from "lodash-es";

import GoogleMap, { latLangToPixelInViewPort, offsetPanFromCenter } from "@Components/GoogleMap";
import useGoogleApi from "@Components/GoogleMap/useGoogleApi";
import useMap from "@Hooks/useMap";

import { Container, ContainerDiv, DarkOverlay, MapNotLoaded, Wrapper } from "./styles";
import ZoomControls from "./ZoomControls";

const { publicRuntimeConfig } = getConfig();

export type MapProp = {
  customMapWrapper?: Interpolation<object>;
  customMapStyles?: google.maps.MapTypeStyle[];
  customMarker?: string;
  displayDarkOverlay?: boolean;
  displayZoomControls?: boolean;
  initialZoom?: number;
  isInteractive?: boolean;
  mapType?: "satellite" | "roadmap" | "hybrid" | "terrain";
  offsetYFromCenter?: number;
  maxZoom?: number;
  minZoom?: number;
  onMapClick?: (position: google.maps.LatLng) => void;
  onMarkerDragEnd?: (latLng: google.maps.LatLng) => void;
  renderMarkerOnLoad?: boolean;
  testId?: string;
  zoomOnClick?: number;
  isForegroundMap?: boolean;
} & ({ postcode: string; latLng?: never } | { postcode?: never; latLng: { lat: number; lng: number } });

const Map = memo(
  ({
    customMapWrapper,
    customMarker = "/_next-public/images/marker.svg",
    customMapStyles,
    displayDarkOverlay = false,
    displayZoomControls = false,
    initialZoom = 17,
    isInteractive = false, // map cannot be interactive with displayDarkOverlay set to true
    latLng,
    mapType = "hybrid",
    maxZoom,
    minZoom,
    offsetYFromCenter = 200,
    onMapClick,
    onMarkerDragEnd,
    postcode,
    renderMarkerOnLoad = true,
    testId,
    zoomOnClick,
    isForegroundMap = false,
  }: MapProp) => {
    const mapRef = useRef<google.maps.Map>(null);
    const [isMapBoundsSet, setIsMapBoundsSet] = useState<boolean>(false);
    const [loadFail, setLoadFail] = useState<string | null>(null);

    const isApiLoaded = useGoogleApi({
      apiKey: publicRuntimeConfig.GOOGLE_MAP_KEY,
      onError: setLoadFail,
    });

    const handleMarkerDragEnd = (position: google.maps.LatLng) => {
      mapRef.current?.panTo(position);
      onMarkerDragEnd?.(position);
    };

    const { center, addMarker, centerFail } = useMap({
      isApiLoaded,
      onMarkerDragEnd: handleMarkerDragEnd,
      postcode,
      latLng,
    });

    const adjustMap = useCallback(() => {
      // Pan map the way so marker will be at navBat.bottom * 3

      if (isMapBoundsSet && mapRef.current) {
        const centerPosition = latLangToPixelInViewPort(mapRef.current, center as google.maps.LatLng);
        // map should be centered, so it can provide the correct boundaries,
        // if boundaries are not exist, it is imposable to convert LatLng to pixel position
        if (centerPosition) {
          const offsetY = centerPosition.y - offsetYFromCenter;
          offsetPanFromCenter(mapRef.current, { x: 0, y: offsetY });
        }
      }
    }, [isMapBoundsSet, center, offsetYFromCenter]);

    useEffect(() => {
      const debouncedAdjustMap = debounce(adjustMap, 200);
      if (isMapBoundsSet && center) {
        debouncedAdjustMap();
        // reCenter map in case postcode been updated
        if (mapRef?.current?.getCenter && mapRef.current.getCenter()) {
          mapRef.current.setCenter(center);
        }

        renderMarkerOnLoad &&
          addMarker({
            icon: customMarker,
            animation: google.maps.Animation.DROP,
            map: mapRef.current,
            position: center,
            draggable: isInteractive,
          });
      }

      window.addEventListener("resize", debouncedAdjustMap);
      return () => {
        window.removeEventListener("resize", debouncedAdjustMap);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMapBoundsSet, adjustMap, center]);

    const isMockShouldBeRendered = centerFail || loadFail;
    const isMapCanBeRendered = center && isApiLoaded && !isMockShouldBeRendered;

    const handleZoom = (zoomDirection: "zoom-in" | "zoom-out") => {
      if (mapRef.current) {
        const currentZoom = mapRef.current.getZoom() || 0;

        const newZoom = zoomDirection === "zoom-in" ? currentZoom + 1 : currentZoom - 1;

        mapRef.current.setZoom(newZoom);
      }
    };

    const handleMapClick = (position: google.maps.LatLng) => {
      onMapClick?.(position);

      zoomOnClick && mapRef.current?.setZoom(zoomOnClick);

      mapRef.current?.panTo(position);

      mapRef.current?.setZoom(20);

      addMarker({
        animation: google.maps.Animation.DROP,
        draggable: true,
        icon: customMarker,
        map: mapRef.current,
        position,
      });
    };

    return (
      <ContainerDiv data-testid={testId}>
        {isMapCanBeRendered && !isForegroundMap ? (
          <Container data-testid="google-map" $isInteractive={isInteractive}>
            {displayDarkOverlay ? <DarkOverlay /> : null}
            <Wrapper>
              <GoogleMap
                ref={mapRef}
                customMapStyles={customMapStyles}
                onMapClick={handleMapClick}
                options={{
                  center,
                  disableDefaultUI: true,
                  mapTypeId: mapType,
                  maxZoom,
                  minZoom,
                  zoom: initialZoom,
                }}
                onBoundsSet={() => setIsMapBoundsSet(true)}
              />
            </Wrapper>
          </Container>
        ) : null}
        {isMapCanBeRendered && isForegroundMap ? (
          <GoogleMap
            customMapWrapper={customMapWrapper}
            ref={mapRef}
            options={{
              center,
              disableDefaultUI: true,
              mapTypeId: mapType,
              zoom: initialZoom,
              draggable: false,
            }}
          />
        ) : null}
        {isMockShouldBeRendered ? <MapNotLoaded /> : null}
        {isMapCanBeRendered && displayZoomControls ? <ZoomControls handleZoom={handleZoom} /> : null}
      </ContainerDiv>
    );
  },
);

export default Map;
