import { useEffect, useState } from "react";
import { breakpoints } from "@boxt/design-system";

import useMedia from "@Hooks/useMedia";

import Video from "./components/Video";
import { Container, DesktopVideoContainer, MobileVideoAspectRatio, MobileVideoContainer } from "./styles";

export type Props = {
  backgroundPoster: string | undefined;
  backgroundVideo: string | undefined;
  isVideoLooping: boolean | undefined;
  mobileBackgroundPoster: string | undefined;
  mobileBackgroundVideo: string | undefined;
  videoDescription: string | undefined;
};

const BackgroundVideo = ({
  backgroundPoster = "",
  backgroundVideo = "",
  isVideoLooping = true,
  mobileBackgroundPoster = "",
  mobileBackgroundVideo = "",
  videoDescription = "",
}: Props) => {
  const [isDesktopVideoPlaying, setIsDesktopVideoPlaying] = useState(false);
  const [isDesktopAutoPlay, setIsDesktopAutoPlay] = useState(true);
  const [isMobileAutoPlay, setIsMobileAutoPlay] = useState(true);
  const [isMobileVideoPlaying, setIsMobileVideoPlaying] = useState(false);
  const [desktopSrc, setDesktopSrc] = useState<string | undefined>();
  const [mobileSrc, setMobileSrc] = useState<string | undefined>();

  const isMobile = useMedia(`(max-width: ${breakpoints.lg.width})`);
  const isDesktop = useMedia(`(min-width: ${breakpoints.lg.width})`);

  const handleOnMobileClick = () => {
    setIsMobileVideoPlaying((prev) => !prev);
    setIsMobileAutoPlay(!isMobileVideoPlaying);
  };

  const handleOnDesktopClick = () => {
    setIsDesktopVideoPlaying((prev) => !prev);
    setIsDesktopAutoPlay(!isDesktopVideoPlaying);
  };

  const handleOnLoad = () => {
    if (isDesktop) {
      setIsDesktopVideoPlaying(true);
    } else {
      setIsMobileVideoPlaying(true);
    }
  };

  // Load the video src when the component is mounted so only 1 video is loaded at a time
  useEffect(() => {
    if (isMobile && !mobileSrc) {
      setMobileSrc(mobileBackgroundVideo);
    }

    if (isDesktop && !desktopSrc) {
      setDesktopSrc(backgroundVideo);
    }
  }, [backgroundVideo, desktopSrc, isDesktop, isMobile, mobileBackgroundVideo, mobileSrc]);

  // If screen size is mobile and the desktop video is playing, stop the desktop video and set state to restart video
  // if going back to desktop
  useEffect(() => {
    if (isMobile && isDesktopVideoPlaying) {
      setIsDesktopVideoPlaying(false);
      setIsDesktopAutoPlay(true);
    }
  }, [isDesktopVideoPlaying, isMobile]);

  // If screen size is desktop and the mobile video is playing, stop the mobile video and set state to restart video
  // if going back to mobile
  useEffect(() => {
    if (isDesktop && isMobileVideoPlaying) {
      setIsMobileVideoPlaying(false);
      setIsMobileAutoPlay(true);
    }
  }, [isMobileVideoPlaying, isDesktop]);

  // If screen size is desktop and the video was playing previously
  // start playing video again
  useEffect(() => {
    if (isDesktop && isDesktopAutoPlay && !isDesktopVideoPlaying) {
      setIsDesktopVideoPlaying(true);
    }
  }, [isDesktop, isDesktopAutoPlay, isDesktopVideoPlaying]);

  // If screen size is mobile and the video was playing previously
  // start playing video again
  useEffect(() => {
    if (isMobile && isMobileAutoPlay && !isMobileVideoPlaying) {
      setIsMobileVideoPlaying(true);
    }
  }, [isMobile, isMobileAutoPlay, isMobileVideoPlaying]);

  return (
    <Container data-testid="background-video">
      <MobileVideoContainer>
        <MobileVideoAspectRatio>
          <Video
            isVideoLooping={isVideoLooping}
            isVideoPlaying={isMobileVideoPlaying}
            onClick={handleOnMobileClick}
            onLoad={handleOnLoad}
            poster={mobileBackgroundPoster}
            testId="mobile-video"
            title={videoDescription}
            videoSrc={mobileSrc}
          />
        </MobileVideoAspectRatio>
      </MobileVideoContainer>
      <DesktopVideoContainer>
        <Video
          isVideoLooping={isVideoLooping}
          isVideoPlaying={isDesktopVideoPlaying}
          onClick={handleOnDesktopClick}
          onLoad={handleOnLoad}
          poster={backgroundPoster}
          testId="desktop-video"
          title={videoDescription}
          videoSrc={desktopSrc}
        />
      </DesktopVideoContainer>
    </Container>
  );
};

export default BackgroundVideo;
