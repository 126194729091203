import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import router from "next/router";
import useTranslation from "next-translate/useTranslation";
import { isEmpty } from "lodash-es";
import { parseCookies } from "nookies";
import useSWR from "swr";
import { BottomSheet, Button, type ButtonProps, Spacing } from "@boxt/design-system";

import createKey from "@Helpers/createKey";
import useGenerateFinishQuoteTiles from "@Helpers/generateFinishQuoteTiles";
import { API_URLS, SCREENING_URLS } from "@Collections/routes";
import { fetcher } from "@DataAccess/local";
import LandingProductTiles from "@Components/pages/common/LandingProductTiles";
import type { ButterProductTile } from "@Components/pages/common/LandingProductTiles/types";
import { SCREENER_COOKIE_KEY } from "@Pages/api/_next/screeners/_constants";
import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

import type { ButterLandingHeroScrollButton } from "../../../types";

export type Props = {
  bottomSheetTiles?: ButterProductTile[];
  getButtonSize: () => ButtonProps["size"];
  isMobile: boolean;
  scrollButton?: ButterLandingHeroScrollButton[];
  handleScrollButtonClick: () => void;
};

const HomePageButtons = ({
  bottomSheetTiles = [],
  getButtonSize,
  isMobile,
  scrollButton,
  handleScrollButtonClick,
}: Props) => {
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [isFinishQuoteBottomSheetOpen, setIsFinishQuoteBottomSheetOpen] = useState(false);

  const { t } = useTranslation("home-page/index");
  const { t: listLocale } = useTranslation("list/index");

  const screenersToCheck = [
    ScreenerVariant.BoilerInstallation,
    ScreenerVariant.Solar,
    ScreenerVariant.BoilerInstallationExperiment,
    ScreenerVariant.HeatPump,
  ];

  const { data, error, isValidating } = useSWR(
    { url: API_URLS.SCREENING_STATUS },
    ({ url }: { url: string }) =>
      Promise.all(screenersToCheck.map((variant) => fetcher(url, { screenerVariant: variant }))),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const isLoading = (!data && !error) || isValidating;

  const existingScreeners =
    (data
      ?.map((screener) => {
        if (screener?.isScreenerComplete) {
          const cookies = parseCookies();
          const screeningId = cookies[`${SCREENER_COOKIE_KEY}:${screener.screenerVariant}`];
          return {
            variant: screener?.screenerVariant,
            url: SCREENING_URLS[screener?.screenerVariant]?.complete.replace("[screeningId]", screeningId),
          };
        } else if (screener?.isScreenerStarted) {
          return {
            variant: screener?.screenerVariant,
            url: SCREENING_URLS[screener?.screenerVariant]?.start,
          };
        }
        return null;
      })
      .filter(Boolean) as { variant: ScreenerVariant; url: string }[]) || [];

  const handleFinishQuoteClick = () => {
    if (Number(existingScreeners?.length) > 1) {
      setIsFinishQuoteBottomSheetOpen(true);
    } else if (existingScreeners?.length === 1) {
      router?.push({
        pathname: existingScreeners?.[0]?.url,
      });
    }
  };

  const finishQuoteTiles = useGenerateFinishQuoteTiles(
    existingScreeners,
    listLocale,
    setIsBottomSheetOpen,
    setIsFinishQuoteBottomSheetOpen,
  ) as ButterProductTile[];

  const shouldShowFinishQuoteButton = !isEmpty(existingScreeners);

  const getButtonSkin = () => {
    return (shouldShowFinishQuoteButton && "secondary") || scrollButton?.[0].skin.name || "primary";
  };

  return (
    <>
      {isLoading ? (
        <Spacing mt={5}>
          <Skeleton width="20%" containerTestId="skeleton-loader" />
        </Spacing>
      ) : (
        <>
          {shouldShowFinishQuoteButton && (
            <Spacing mr={{ sm: 0, md: 1 }} mb={{ sm: 1, md: 0 }} key={createKey("finish-quote-button")}>
              <Button
                size={getButtonSize()}
                skin="primary"
                boxtTheme="jade"
                onClick={handleFinishQuoteClick}
                data-testid="finish-quote-button"
                isFullWidth={isMobile}
              >
                {t("finish-your-quote")}
              </Button>
            </Spacing>
          )}
          {scrollButton?.[0] && (
            <Spacing mr={{ sm: 0, md: 1 }} mb={{ sm: 1, md: 0 }} key={createKey(scrollButton?.[0].text as string)}>
              <Button
                size={getButtonSize()}
                skin={getButtonSkin()}
                boxtTheme={isEmpty(scrollButton[0].theme) ? "coral" : scrollButton[0].theme.theme}
                isFullWidth={isMobile}
                onClick={handleScrollButtonClick}
                data-testid="scroll-button"
              >
                {scrollButton[0].text}
              </Button>
            </Spacing>
          )}
        </>
      )}
      <BottomSheet
        open={isBottomSheetOpen}
        setIsOpen={setIsBottomSheetOpen}
        headerTitle={t("start-a-new-quote")}
        maxWidth={1200}
        maxHeight={700}
        blocking
      >
        <LandingProductTiles
          idAttribute={"product-tiles-bottom-sheet"}
          fields={{ tiles: bottomSheetTiles }}
          isBottomSheet
        />
      </BottomSheet>
      <BottomSheet
        open={isFinishQuoteBottomSheetOpen}
        setIsOpen={setIsFinishQuoteBottomSheetOpen}
        headerTitle={t("finish-your-quote")}
        maxWidth={1200}
        maxHeight={700}
        blocking
      >
        <LandingProductTiles
          idAttribute={"product-tiles-bottom-sheet"}
          fields={{ tiles: finishQuoteTiles }}
          isBottomSheet
        />
      </BottomSheet>
    </>
  );
};

export default HomePageButtons;
