import { isEmpty } from "lodash-es";
import { breakpoints, Button, Spacing } from "@boxt/design-system";

import type { ButterButtonCollectionItem, ButtonSkinItem } from "@Collections/types/butter/collectionItem";
import useMedia from "@Hooks/useMedia";

import { Container } from "./styles";

export type Props = {
  isLoading: boolean;
  onFinishQuote: () => void;
  onStartQuote: () => void;
  startQuoteButtonText: string | undefined;
  finishQuoteButtonText: string | undefined;
  finishQuoteButtonTheme: ButterButtonCollectionItem | undefined;
  finishQuoteButtonSkin: ButtonSkinItem | undefined;
  startQuoteButtonSkin: ButtonSkinItem | undefined;
  startQuoteButtonTheme: ButterButtonCollectionItem | undefined;
};

const FinishQuote = ({
  isLoading,
  onFinishQuote,
  onStartQuote,
  startQuoteButtonText,
  finishQuoteButtonText,
  finishQuoteButtonTheme,
  finishQuoteButtonSkin,
  startQuoteButtonSkin,
  startQuoteButtonTheme,
}: Props) => {
  const isMobile = useMedia(`(max-width: ${breakpoints.md.width})`);
  const isDesktop = useMedia(`(min-width: ${breakpoints.lg.width})`);

  const getButtonSize = () => {
    if (isDesktop) {
      return "large";
    }

    if (isMobile) {
      return "small";
    }

    return "medium";
  };

  return (
    <Container>
      <Spacing mr={{ sm: 0, md: 1 }} mb={{ sm: 1, md: 0 }}>
        <Button
          isFullWidth={isMobile}
          size={getButtonSize()}
          skin={isEmpty(finishQuoteButtonSkin) ? "primary" : finishQuoteButtonSkin?.name}
          boxtTheme={isEmpty(finishQuoteButtonTheme) ? "coral" : finishQuoteButtonTheme?.theme}
          loadingConfig={{
            isLoading,
          }}
          onClick={onFinishQuote}
        >
          {finishQuoteButtonText}
        </Button>
      </Spacing>
      <Button
        size={getButtonSize()}
        skin={isEmpty(startQuoteButtonSkin) ? "primary" : startQuoteButtonSkin?.name}
        boxtTheme={isEmpty(startQuoteButtonTheme) ? "coral" : startQuoteButtonTheme?.theme}
        data-testid="start-quote-button"
        isFullWidth={isMobile}
        onClick={onStartQuote}
      >
        {startQuoteButtonText}
      </Button>
    </Container>
  );
};

export default FinishQuote;
