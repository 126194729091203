import { breakpoints, Button, Grid, H3, H4, Paragraph, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";
import useMedia from "@Hooks/useMedia";

import { Card, CardRow, Cotainer, ImageContainer, StepperRow, StepWrapper, StyledImage, TitleDiv } from "./styles";
import type { ButterHowBoxtWorksFields } from "./types";

export type Props = {
  fields: ButterHowBoxtWorksFields;
};

const HowBoxtWorks = ({ fields }: Props) => {
  const isLg = useMedia(`(min-width: ${breakpoints.lg.width})`);

  const { title, copy, button, steps } = fields;

  const stepSVG = {
    0: "/_next-public/images/stepper/1.svg",
    1: "/_next-public/images/stepper/2.svg",
    2: "/_next-public/images/stepper/3.svg",
    3: "/_next-public/images/stepper/4.svg",
  };

  const getCardWidth = () => (steps.length === 4 && isLg ? 300 : !isLg ? 225 : 400);

  return (
    <Grid>
      <Spacing mv={3}>
        <TitleDiv>
          <H3>{title}</H3>
        </TitleDiv>
      </Spacing>
      <Cotainer>
        <CardRow>
          {steps.map((card, index) => (
            <ImageContainer key={index}>
              <StyledImage
                src={card.image}
                alt={`image-${index}`}
                objectFit="cover"
                width={getCardWidth()}
                height={isLg ? 300 : 130}
                data-testid="step-image"
              />
            </ImageContainer>
          ))}
        </CardRow>
        <div>
          <StepperRow>
            {steps.map((_, index) => (
              <StepWrapper $cardCount={steps.length} key={index}>
                <Image src={stepSVG[index]} alt={`svg-${index}`} width={20} height={20}></Image>
              </StepWrapper>
            ))}
          </StepperRow>
        </div>
        <CardRow>
          {steps.map((card, index) => (
            <Card key={index} $cardCount={steps.length}>
              <Spacing mb={1}>
                <H4>{card.step_title}</H4>
              </Spacing>
              <Paragraph size={!isLg ? "small" : "medium"}>{card.body}</Paragraph>
            </Card>
          ))}
        </CardRow>
      </Cotainer>
      <Spacing mv={3}>
        {copy && (
          <TitleDiv>
            <H4 weight="regular">{copy}</H4>
          </TitleDiv>
        )}
      </Spacing>
      <TitleDiv>
        {button[0] && (
          <Button
            boxtTheme={button[0]?.theme?.theme || "jade"}
            skin={button[0]?.skin?.name || "primary"}
            href={button[0]?.url}
          >
            {button[0]?.title}
          </Button>
        )}
      </TitleDiv>
    </Grid>
  );
};

export default HowBoxtWorks;
