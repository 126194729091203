import { useState } from "react";
import { H3, H4, H5, Paragraph } from "@boxt/design-system";

import { FONT_COLOR } from "@Collections/themeColors";
import { useAnalytics } from "@Hooks/useAnalytics";

import ValuePropBottomSheet from "./components/ValuePropBottomSheet";
import { Container, IconWrapper, StyledSvg, ValuePropButton, ValuePropWrapper } from "./styles";
import type { ButterValuePropBannerFields } from "./types";

export type Props = {
  fields: ButterValuePropBannerFields;
};

const ValuePropBanner = ({ fields }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    title,
    strapline,
    explainer,
    title_theme: titleTheme,
    strapline_theme: straplineTheme,
    explainer_theme: explainerTheme,
    icon_stroke_colour: iconStrokeColour,
    value_prop_background_colour: valuePropBackgroundColour,
    value_prop_title_theme: valuePropTitleTheme,
    value_prop_fields: valuePropFields,
  } = fields;

  const segmentAnalytics = useAnalytics();

  const fireSegmentClickEvent = (ctaTitle: string) => {
    if (segmentAnalytics) {
      segmentAnalytics?.track("cta_clicked", {
        cta_name: `value_prop_${ctaTitle}`,
        page: "BOXT Life PLP",
        category: "boiler_subscribe",
      });
    }
  };

  const onPropClick = (ctaTitle: string) => {
    setIsOpen(true);
    fireSegmentClickEvent(ctaTitle);
  };

  return (
    <>
      <Container>
        <H4 weight="regular" boxtTheme={titleTheme?.name ? titleTheme?.name : "slate"}>
          {title}
        </H4>
        <H3 boxtTheme={straplineTheme?.name ? straplineTheme?.name : "slate"}>{strapline}</H3>
        <H5 weight="regular" boxtTheme={explainerTheme?.name ? explainerTheme.name : "slate"}>
          {explainer}
        </H5>
        <ValuePropWrapper>
          {valuePropFields?.map((field, index) => (
            <ValuePropButton
              key={index}
              onClick={() => onPropClick(field.title)}
              $bgColour={FONT_COLOR[valuePropBackgroundColour?.name]}
              data-testid={`value-prop-button-${index}`}
            >
              <IconWrapper>
                <StyledSvg $strokeColour={FONT_COLOR[iconStrokeColour?.name]} src={field.icon} width={44} height={44} />
              </IconWrapper>
              <Paragraph
                boxtTheme={valuePropTitleTheme?.name ? valuePropTitleTheme?.name : "slate"}
                weight="bold"
                align="left"
                top={8}
              >
                {field.title}
              </Paragraph>
            </ValuePropButton>
          ))}
        </ValuePropWrapper>
      </Container>
      <ValuePropBottomSheet isOpen={isOpen} setIsOpen={setIsOpen} fields={fields} />
    </>
  );
};

export default ValuePropBanner;
