import { useEffect, useState } from "react";
import loadjs from "loadjs";

const useGoogleAPI = ({ apiKey, onError }: { apiKey: string; onError: (error: string | null) => void }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!apiKey) {
      onError("GOOGLE_MAP_KEY is not defined in env variables");
    }
    if (!loadjs.isDefined("gmap")) loadjs(`https://maps.googleapis.com/maps/api/js?key=${apiKey}`, "gmap");
    loadjs.ready("gmap", {
      success: () => {
        setLoaded(true);
        onError(null);
      },
      error: () => {
        onError("Failed to load google maps");
      },
    });
  }, [apiKey, onError]);
  return loaded;
};

export default useGoogleAPI;
