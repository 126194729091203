import { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { Paragraph } from "@boxt/design-system";

import Image from "@Components/Image";

import TvAdvertPopup from "./components/TvAdvertPopup";
import { Container, ImageContainer } from "./styles";

export type Props = {
  advertBadgeVideoId: string;
  advertBadgeText?: string;
};

const TvAdvertBadge = ({ advertBadgeVideoId, advertBadgeText }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation("common");

  const handleOnClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Container onClick={handleOnClick} data-testid="watch-tv-ad">
        <ImageContainer>
          <Image width={34} height={20} src="/_next-public/images/tv-advert.gif" alt={t("watch-tv-advert-image-alt")} />
        </ImageContainer>
        <Paragraph size="small" boxtTheme="dark" weight="bold" as="span">
          {advertBadgeText || t("watch-tv-advert")}
        </Paragraph>
      </Container>
      {isModalOpen && <TvAdvertPopup videoId={advertBadgeVideoId} onDismiss={() => setIsModalOpen(false)} />}
    </>
  );
};

export default TvAdvertBadge;
