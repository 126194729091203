import styled from "styled-components";
import { includeSpacing } from "@boxt/design-system";

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;

  ${includeSpacing({
    mt: { sm: 4, md: 5 },
    mb: { sm: 3, md: 4 },
  })}
`;
