import styled from "styled-components";
import { includeSpacing } from "@boxt/design-system";

export const UspBadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const UspBadge = styled.div`
  flex: 0 0 70px;
  ${includeSpacing({ mr: { sm: 1, md: 2 } })}
`;
