import { useLenis } from "@studio-freight/react-lenis";
import { Spacing } from "@boxt/design-system";
import { breakpoints } from "@boxt/design-system";

import ImageComponent from "@Components/pages/common/LandingImageCollection/ImageCollectionColumn/ImageComponent";
import { ImageSize } from "@Components/pages/common/LandingImageCollection/ImageCollectionColumn/ImageComponent/ImageComponent";
import {
  StyledLargeImageWrapper,
  StyledSmallImagesWrapper,
} from "@Components/pages/common/LandingImageCollection/ImageCollectionColumn/styles";
import type { ButterImageCollectionFields } from "@Components/pages/common/LandingImageCollection/types";
import ParallaxComponent from "@Components/ParallaxComponent";
import useMedia from "@Hooks/useMedia";

const ImageCollectionColumn = ({ images: [image1, image2, image3] }: ButterImageCollectionFields) => {
  const isLg = useMedia(`(min-width: ${breakpoints.lg.width})`);

  // Avoid Cumulative Layout Shift to help smooth scroll and parallax effect
  image1 = isLg
    ? { ...image1, width: 364, height: 310, quality: 0 }
    : { ...image1, layout: "fill", objectFit: "cover" };

  image2 = isLg
    ? { ...image2, width: 364, height: 310, quality: 0 }
    : { ...image2, layout: "fill", objectFit: "cover" };

  image3 = isLg
    ? { ...image3, width: 364, height: 639, quality: 0 }
    : { ...image3, layout: "fill", objectFit: "cover" };

  return (
    <>
      <StyledSmallImagesWrapper data-testid="small-image-wrapper">
        <ImageComponent image={image1} size={ImageSize.sm} />
        <Spacing mt={{ sm: 1, lg: 2 }} />
        <ImageComponent image={image2} size={ImageSize.sm} />
      </StyledSmallImagesWrapper>
      <StyledLargeImageWrapper data-testid="large-image-wrapper">
        <ParallaxComponent useScroll={useLenis}>
          <ImageComponent image={image3} size={ImageSize.lg} />
        </ParallaxComponent>
      </StyledLargeImageWrapper>
    </>
  );
};

export default ImageCollectionColumn;
