import Trans from "next-translate/Trans";
import { breakpoints, Button, H3, H5, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";
import useMedia from "@Hooks/useMedia";

import type { OwnershipMethodType } from "../../types";

import { BackgroundImage, Container, ContentWrapper, TextWrapper, Wrapper } from "./styles";

type Props = {
  method: OwnershipMethodType;
};

const OwnershipMethod = ({ method }: Props) => {
  const { key, title, buttonText, buttonTheme, onClick, imageSrc, isLoadingScreener, bgImage, theme, testId } = method;
  const isMobile = useMedia(`(max-width: ${breakpoints.md.width})`);

  return (
    <Container $boxtTheme={theme}>
      {bgImage && <BackgroundImage src={bgImage} alt="" layout="fill" objectFit="contain" objectPosition="left" />}
      <Wrapper mh={{ sm: 2, md: 3, lg: 4 }} mb={{ sm: 4, lg: 5 }}>
        <ContentWrapper>
          <div>
            <Image src={imageSrc} alt={title} width={isMobile ? 136 : 272} height={isMobile ? 90 : 180} />
          </div>
          <TextWrapper>
            <Spacing mb={{ sm: 2, md: 3, lg: 4 }} mt={{ sm: 2, md: 4, lg: 5 }}>
              <H3 weight="bold" bottom={{ sm: 3, md: 5, lg: 6 }}>
                {title}
              </H3>
              <H5 weight="regular">
                <Trans i18nKey={`boilers/index:life-popup.${key}.subtitle`} components={{ strong: <strong /> }} />
              </H5>
            </Spacing>
            <div>
              <Button
                boxtTheme={buttonTheme}
                onClick={onClick}
                data-testid={testId}
                loadingConfig={{
                  isLoading: isLoadingScreener,
                }}
              >
                {buttonText}
              </Button>
            </div>
          </TextWrapper>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default OwnershipMethod;
