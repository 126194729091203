import useTranslation from "next-translate/useTranslation";
import { Grid, Paragraph, ToolTip } from "@boxt/design-system";

import { ReactComponent as Cross } from "@Images/icons/comparison-cross.svg";
import { ReactComponent as Tick } from "@Images/icons/comparison-tick.svg";
import { ReactComponent as Info } from "@Images/icons/comparison-tooltip.svg";

import { AnswerCol, IconWrapper, TooltipWrapper } from "./styles";
import type { Answer, Theme } from "./types";

type Props = {
  answer: Omit<Answer, "key">;
  theme: Theme;
  hoverBehaviour: "click" | "hover";
  isLastRow: boolean;
  i18nNamespace: string;
};

export const AnswerChild = ({ answer, theme, hoverBehaviour, isLastRow, i18nNamespace }: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const { iconName, text, tooltipText } = answer;

  return (
    <Grid.Col
      sm={1}
      md={2}
      lg={3}
      role="cell"
      as={AnswerCol}
      $theme={theme}
      $isLastRow={isLastRow}
      $hasTooltip={Boolean(tooltipText)}
    >
      {text ? (
        <>
          <Paragraph boxtTheme="slate" weight="bold" align="center">
            {t("comparison-table.answer", { answer: text })}
          </Paragraph>
          {tooltipText && (
            <TooltipWrapper>
              <ToolTip toolTipText={tooltipText} triggerAction={hoverBehaviour}>
                <Info />
              </ToolTip>
            </TooltipWrapper>
          )}
        </>
      ) : (
        <IconWrapper>{iconName === "tick" ? <Tick /> : <Cross />}</IconWrapper>
      )}
    </Grid.Col>
  );
};
