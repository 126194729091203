import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { isEmpty } from "lodash-es";
import { parseCookies } from "nookies";
import useSWR from "swr";
import { BottomSheet, Grid, Paragraph, Spacing } from "@boxt/design-system";

import useGenerateFinishQuoteTiles from "@Helpers/generateFinishQuoteTiles";
import { API_URLS, SCREENING_URLS } from "@Collections/routes";
import { fetcher } from "@DataAccess/local";
import Image from "@Components/Image";
import { SCREENER_COOKIE_KEY } from "@Pages/api/_next/screeners/_constants";
import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

import Buttons from "../ButterTitleHero/components/Buttons";
import LandingProductTiles from "../LandingProductTiles";
import type { ButterProductTile } from "../LandingProductTiles/types";

import {
  ContainerWithBackgroundImage,
  DesktopBackgroundImageContainer,
  Heading,
  HeadingContainer,
  HeroContainer,
  ImageContainer,
  MobileBackgroundImageContainer,
  StyledImageCol,
} from "./styles";
import type { Butter5050HeroFields } from "./types";

export type Props = {
  fields: Butter5050HeroFields;
};

const Butter5050Hero = ({ fields }: Props) => {
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [isFinishQuoteBottomSheetOpen, setIsFinishQuoteBottomSheetOpen] = useState(false);

  const router = useRouter();

  const { t } = useTranslation("list/index");

  const screenersToCheck = [
    ScreenerVariant.BoilerInstallation,
    ScreenerVariant.Solar,
    ScreenerVariant.BoilerSubscription,
    ScreenerVariant.HeatPump,
    ScreenerVariant.AirCondition,
  ];

  const { data, error, isValidating } = useSWR(
    { url: API_URLS.SCREENING_STATUS },
    ({ url }: { url: string }) =>
      Promise.all(screenersToCheck.map((variant) => fetcher(url, { screenerVariant: variant }))),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const existingScreeners =
    (data
      ?.map((screener) => {
        if (screener?.isScreenerComplete) {
          const cookies = parseCookies();
          const screeningId = cookies[`${SCREENER_COOKIE_KEY}:${screener.screenerVariant}`];
          return {
            variant: screener?.screenerVariant,
            url: SCREENING_URLS[screener?.screenerVariant]?.complete.replace("[screeningId]", screeningId),
          };
        } else if (screener?.isScreenerStarted) {
          return {
            variant: screener?.screenerVariant,
            url: SCREENING_URLS[screener?.screenerVariant]?.start,
          };
        }
        return null;
      })
      .filter(Boolean) as { variant: ScreenerVariant; url: string }[]) || [];

  const handleFinishQuoteClick = () => {
    if (Number(existingScreeners?.length) > 1) {
      setIsFinishQuoteBottomSheetOpen(true);
    } else if (existingScreeners?.length === 1) {
      router?.push({
        pathname: existingScreeners[0]?.url,
      });
    }
  };

  const finishQuoteTiles = useGenerateFinishQuoteTiles(
    existingScreeners,
    t,
    setIsBottomSheetOpen,
    setIsFinishQuoteBottomSheetOpen,
  ) as ButterProductTile[];

  const {
    background_gradient_end_colour: gradientEndColor,
    background_gradient_start_colour: gradientStartColor,
    background_image_desktop: desktopBackgroundImage,
    background_image_mobile: mobileBackgroundImage,
    copy,
    copy_theme: copyTheme,
    desktop_image: desktopImage,
    image_alt: imageAlt,
    has_overflow_content: hasOverflowContent,
    title,
    title_highlighted_theme: titleHighlightedTheme,
    title_theme: titleTheme,
    tiles = [],
    bottom_sheet_header: bottomSheetHeader,
    bottom_sheet_button: bottomSheetButton,
    button: defaultButton,
    include_finish_quote_button: displayFinishQuoteButton,
    hero_version: heroVersion,
  } = fields;

  const hasBackgroundImages = Boolean(desktopBackgroundImage && mobileBackgroundImage);

  const hasFinishQuoteButton = displayFinishQuoteButton && !isEmpty(existingScreeners);
  const hasDefaultButton = !isEmpty(defaultButton) && heroVersion === "no-bottom-sheet";
  const hasBottomSheetButton = !isEmpty(bottomSheetButton) && heroVersion === "new-quote-bottom-sheet";

  const isLoading = (!data && !error) || isValidating;

  const renderContent = () => {
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Col sm={4} md={8} lg={6} as={StyledImageCol}>
              {desktopImage && (
                <ImageContainer data-testid="butter-5050-hero-main-image">
                  <Image alt={imageAlt} src={desktopImage} layout="fill" />
                </ImageContainer>
              )}
            </Grid.Col>
            <Grid.Col xlg={{ offset: 1, span: 7 }} lg={{ offset: 1, span: 8 }} md={8} sm={4}>
              <Spacing mb={2} mt={{ sm: 2, md: 2, lg: 7 }} as={HeadingContainer}>
                <Heading
                  weight="regular"
                  $titleHighlightedTheme={titleHighlightedTheme?.length ? titleHighlightedTheme[0]?.name : "coalLight"}
                  boxtTheme={titleTheme?.length ? titleTheme[0].name : "coalLight"}
                >
                  {title}
                </Heading>
              </Spacing>
            </Grid.Col>
          </Grid.Row>
          {copy && (
            <Grid.Row>
              <Grid.Col lg={{ offset: 1, span: 6 }} md={8} sm={4}>
                <Paragraph weight="regular" size="medium" boxtTheme={copyTheme?.name ?? "coalLight"}>
                  {copy}
                </Paragraph>
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Col lg={{ offset: 1, span: 6 }} md={8} sm={4}>
              <Spacing mt={4}>
                {isLoading ? (
                  <Skeleton width="20%" containerTestId="skeleton-loader" />
                ) : (
                  <Buttons
                    bottomSheetButton={bottomSheetButton}
                    setIsBottomSheetOpen={setIsBottomSheetOpen}
                    defaultButton={defaultButton}
                    handleFinishQuoteClick={handleFinishQuoteClick}
                    i18nNamespace="list/index"
                    hasFinishQuoteButton={hasFinishQuoteButton}
                    hasDefaultButton={hasDefaultButton}
                    hasBottomSheetButton={hasBottomSheetButton}
                  />
                )}
              </Spacing>
            </Grid.Col>
          </Grid.Row>
        </Grid>
        <BottomSheet
          open={isBottomSheetOpen}
          setIsOpen={setIsBottomSheetOpen}
          headerTitle={bottomSheetHeader}
          maxWidth={1200}
          maxHeight={800}
          blocking
        >
          <LandingProductTiles idAttribute={"product-tiles-bottom-sheet"} fields={{ tiles }} isBottomSheet />
        </BottomSheet>
        <BottomSheet
          open={isFinishQuoteBottomSheetOpen}
          setIsOpen={setIsFinishQuoteBottomSheetOpen}
          headerTitle={t("title-hero.finish-your-quote")}
          maxWidth={1200}
          maxHeight={800}
          blocking
        >
          <LandingProductTiles
            idAttribute={"product-tiles-bottom-sheet"}
            fields={{ tiles: finishQuoteTiles }}
            isBottomSheet
          />
        </BottomSheet>
      </>
    );
  };

  return hasBackgroundImages ? (
    <ContainerWithBackgroundImage $hasOverflowContent={hasOverflowContent}>
      <DesktopBackgroundImageContainer data-testid="butter-5050-hero-desktop-background-image">
        <Image src={desktopBackgroundImage as string} layout="fill" alt="" objectFit="cover" objectPosition="top" />
      </DesktopBackgroundImageContainer>
      <MobileBackgroundImageContainer data-testid="butter-5050-hero-mobile-background-image">
        <Image src={mobileBackgroundImage as string} layout="fill" alt="" objectFit="cover" objectPosition="top" />
      </MobileBackgroundImageContainer>
      {renderContent()}
    </ContainerWithBackgroundImage>
  ) : (
    <HeroContainer
      $hasOverflowContent={hasOverflowContent}
      $gradientStartColor={gradientStartColor}
      $gradientEndColor={gradientEndColor}
    >
      {renderContent()}
    </HeroContainer>
  );
};

export default Butter5050Hero;
