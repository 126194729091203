import type { EventArgs } from "react-ga";
import { breakpoints } from "@boxt/design-system";

import Image from "@Components/Image";
import ButtonsSection from "@Components/pages/common/LandingWhySection/ButtonsSection/ButtonsSection";
import Heading from "@Components/pages/common/LandingWhySection/Heading/Heading";
import { Container, StyledVideo, VideoWrapper } from "@Components/pages/common/LandingWhySection/styles";
import { ContentWrapper } from "@Components/pages/common/LandingWhySection/styles";
import type { ButterWhySectionFields } from "@Components/pages/common/LandingWhySection/types";
import useBackgroundUrl from "@Components/pages/common/LandingWhySection/useBackgroundUrl";
import WhySectionDetails from "@Components/pages/common/LandingWhySection/WhySectionDetails/WhySectionDetails";
import useMedia from "@Hooks/useMedia";

import { ComparisonTable } from "../ComparisonTable/ComparisonTable";
import { tableContent } from "../ComparisonTable/content";

export type Props = {
  content: ButterWhySectionFields;
  showComparisonTable?: boolean;
  gaScrollEvent?: EventArgs;
};

const LandingWhySection = ({ content, showComparisonTable, gaScrollEvent }: Props) => {
  const isMobile = useMedia(`(max-width: ${breakpoints.md.width})`);

  const backgroundUrl: string = useBackgroundUrl(content.background);

  return (
    <Container id={content?.id}>
      {Boolean(backgroundUrl) && <Image src={backgroundUrl} layout="fill" alt="" objectFit="cover" />}
      {!isMobile && Boolean(content.video_background) && (
        <VideoWrapper>
          <StyledVideo muted autoPlay loop>
            <source src={content.video_background} type="video/mp4" />
          </StyledVideo>
        </VideoWrapper>
      )}
      <ContentWrapper $showComparisonTable={showComparisonTable}>
        {showComparisonTable ? (
          <ComparisonTable content={tableContent} i18nNamespace="common" testId="boxt-life-comparison-table" />
        ) : (
          <>
            <Heading
              header={content.header}
              secondaryText={content.secondary_text}
              headerTheme={content?.header_theme?.name}
            />
            <WhySectionDetails
              imageSrc={content.image_5050}
              contentHeader={content.content_header_5050}
              bulletPoints={content.content_bullet_points_5050}
              contentLogo={content.content_logo_5050}
              bulletPointsIcon={content.content_bullet_point_icon_5050}
            />
          </>
        )}
        {Boolean(content.main_cta_text) && (
          <ButtonsSection
            secondaryCtaText={content.secondary_cta_text}
            secondaryCtaTheme={content.secondary_cta_theme?.theme || "dark"}
            secondaryCtaURL={content.secondary_cta_url}
            mainCtaText={content.main_cta_text}
            mainCtaTheme={content.main_cta_theme?.theme || "coral"}
            gaScrollEvent={gaScrollEvent}
            showComparisonTable={showComparisonTable}
          />
        )}
      </ContentWrapper>
    </Container>
  );
};

export default LandingWhySection;
