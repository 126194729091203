import { useState } from "react";
import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";
const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

import createKey from "@Helpers/createKey";
import { ReactComponent as PauseIcon } from "@Images/icons/pause.svg";
import { ReactComponent as PlayIcon } from "@Images/icons/play-video.svg";

import { VideoButton } from "./styles";

export type Props = {
  isVideoLooping: boolean;
  isVideoPlaying: boolean;
  onClick: () => void;
  onLoad: () => void;
  poster: string;
  testId?: string;
  title: string;
  videoSrc: string | undefined;
};

const Video = ({ isVideoLooping, poster, title, videoSrc, onClick, isVideoPlaying, onLoad, testId }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const [hasError, setHasError] = useState(false);

  const buttonLabel = isVideoPlaying ? tCommon("pause-video") : tCommon("play-video");

  return (
    <>
      <ReactPlayer
        url={videoSrc}
        playsinline
        playing={isVideoPlaying}
        controls={false}
        muted
        loop={isVideoLooping}
        onReady={onLoad}
        onError={() => setHasError(true)}
        height="100%"
        width="100%"
        config={{
          file: {
            attributes: {
              "data-testid": testId,
              poster,
              title,
              style: {
                objectFit: "cover",
                position: "absolute",
                width: "100%",
                top: 0,
                height: "100%",
              },
            },
          },
        }}
      />
      {hasError ? null : (
        <VideoButton data-testid={createKey(`${testId}-${buttonLabel}`)} aria-label={buttonLabel} onClick={onClick}>
          {isVideoPlaying ? <PauseIcon /> : <PlayIcon />}
        </VideoButton>
      )}
    </>
  );
};

export default Video;
