import { Products } from "@Collections/products";

import NextApi from "src/dataAccess/local";

const getProductTypeForApi = (productType?: Products): Products | undefined => {
  switch (productType) {
    case Products.BoilerRental:
    case Products.HomeCover:
      return Products.Boiler;
    default:
      return productType;
  }
};

export const validateRegion = async (postcode: string, productType?: Products) => {
  const { data } = await NextApi.get("/region", {
    params: { postcode, productType: getProductTypeForApi(productType) },
  });

  return data;
};

export const validateAddress = async (address: string) => NextApi.get("/addresses/validate", { params: { address } });

export default validateRegion;
