import styled from "styled-components";
import { breakpoint, rem } from "@boxt/design-system";

export const VideoButton = styled.button`
  padding: 0;
  border: 0;
  outline: none;
  appearance: none;
  cursor: pointer;
  width: ${rem(40)};
  height: ${rem(40)};
  position: absolute;
  bottom: ${rem(48)};
  right: ${rem(16)};
  background: none;

  ${breakpoint("md")} {
    right: ${rem(32)};
  }

  ${breakpoint("lg")} {
    bottom: ${rem(26)};
  }
`;
