import { useMemo } from "react";
import type { KeenSliderOptions } from "keen-slider/react";
import { Spacing } from "@boxt/design-system";

import { getKeenSliderOptions } from "@Components/pages/common/LandingImageSlider/helpers";
import {
  CustomDotIcon,
  DotsStyle,
  SlideWrapper,
  StyledImage,
  WrapperStyle,
} from "@Components/pages/common/LandingImageSlider/styles";
import type { ButterImageSlide, ButterImageSliderFields } from "@Components/pages/common/LandingImageSlider/types";
import Slider from "@Components/pages/common/Slider";

type Props = {
  fields: ButterImageSliderFields;
  keyName: string;
};

const LandingImageSlider = ({ fields: { images: slides }, keyName }: Props) => {
  const sliderConfig: KeenSliderOptions = useMemo(() => getKeenSliderOptions(slides?.length), [slides]);

  return (
    <Spacing mv={{ sm: 4, md: 5 }}>
      <Slider
        duration={6000}
        includeSpacing={false}
        wrapperStyle={WrapperStyle}
        DotIcon={CustomDotIcon}
        alwaysShowDots
        controllerStyle={DotsStyle}
        showArrows
        sliderOptions={sliderConfig}
      >
        {slides.map((slide: ButterImageSlide, i) => (
          <SlideWrapper className={`keen-slider__slide number-slide-${i}`} key={`${keyName}-${i}`}>
            <StyledImage src={slide.image} alt={slide.alt} layout="fill" objectFit="cover" />
          </SlideWrapper>
        ))}
      </Slider>
    </Spacing>
  );
};

export default LandingImageSlider;
