import { breakpoints } from "@boxt/design-system";

import useMedia from "@Hooks/useMedia";

import { TilesWrapper } from "../../styles";
import type { AchievementTileFields } from "../../types";
import AchievementTilesColumn from "../AchievementTilesColumn";
import FirstTileWithVan from "../FirstTileWithVan";
import { StyledGridCol, StyledGridRow } from "../styles";

type Props = {
  tiles: AchievementTileFields[];
};

const AchievementTilesCollection = ({ tiles }: Props) => {
  const isLg = useMedia(`(min-width: ${breakpoints.lg.width})`);

  return (
    <TilesWrapper $isLg={isLg}>
      {isLg ? (
        <StyledGridRow>
          <StyledGridCol>
            <FirstTileWithVan
              amount={tiles[0].amount}
              description={tiles[0].description}
              width={272}
              height={102}
              isAnimated={tiles[0].tally_animation}
            />
          </StyledGridCol>
          <AchievementTilesColumn tiles={[tiles[1], tiles[2]]} />
          <AchievementTilesColumn tiles={[tiles[3], tiles[4]]} />
          <AchievementTilesColumn tiles={[tiles[5], tiles[6]]} />
        </StyledGridRow>
      ) : (
        <StyledGridRow>
          <AchievementTilesColumn tiles={[tiles[3], tiles[4]]} firstTile={tiles[0]} width={252} height={96} />
          <AchievementTilesColumn tiles={[tiles[1], tiles[2], tiles[5], tiles[6]]} />
        </StyledGridRow>
      )}
    </TilesWrapper>
  );
};

export default AchievementTilesCollection;
