import { breakpoints } from "@boxt/design-system";

import type { ButterProductTileCollectionItem, SliderProductTile } from "@Collections/types/butter/collectionItem";
import Card from "@Components/pages/common/LandingProductTiles/Card";
import { getConfig } from "@Components/pages/common/LandingProductTiles/CardsSlider/config";
import { Container } from "@Components/pages/common/LandingProductTiles/CardsSlider/styles";
import Slider from "@Components/pages/common/Slider/Slider";
import type { ArrowTheme } from "@Components/pages/common/Slider/types";
import useMedia from "@Hooks/useMedia";

type Props = {
  cardItems: SliderProductTile[];
  idAttribute: string;
  isBottomSheet: boolean;
};

const arrowTheme: ArrowTheme = { size: "small", color: "light", position: "outside" };

const CardsSlider = ({ cardItems = [], idAttribute, isBottomSheet }: Props) => {
  const isLg = useMedia(`(min-width: ${breakpoints.lg.width})`);

  return (
    <Container>
      <Slider
        duration={0}
        initiallyLoop={false}
        includeSpacing={false}
        sliderOptions={getConfig(cardItems.length)}
        showDotsController={false}
        showArrows={isLg}
        arrowTheme={arrowTheme}
      >
        {cardItems.map((cardItem: ButterProductTileCollectionItem, index: number) => (
          <div className="keen-slider__slide" key={`${idAttribute}-${index}`}>
            <Card
              cardConfig={cardItem}
              cardIndex={index}
              lastCard={index === cardItems.length - 1}
              firstCard={index === 0}
              isBottomSheet={isBottomSheet}
            />
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default CardsSlider;
