import { Add, Subtract } from "@carbon/icons-react";
import { Spacing } from "@boxt/design-system";

import { ZoomButton, ZoomControlsContainer } from "./styles";

export type Props = {
  handleZoom: (zoomType: "zoom-in" | "zoom-out") => void;
};

const ZoomControls = ({ handleZoom }: Props) => {
  return (
    <ZoomControlsContainer>
      <Spacing mh={{ sm: 1, lg: 2 }}>
        <ZoomButton
          data-testid="zoom-out"
          size="small"
          onClick={() => {
            handleZoom("zoom-out");
          }}
        >
          <Subtract size={32} color="#2D3D4D" />
        </ZoomButton>
      </Spacing>
      <Spacing mh={{ sm: 1, lg: 2 }}>
        <ZoomButton
          data-testid="zoom-in"
          size="small"
          onClick={() => {
            handleZoom("zoom-in");
          }}
        >
          <Add size={32} color="#2D3D4D" />
        </ZoomButton>
      </Spacing>
    </ZoomControlsContainer>
  );
};

export default ZoomControls;
