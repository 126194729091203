import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

const useGenerateLandingProductTiles = (
  existingScreeners: { variant: ScreenerVariant; url: string }[],
  t: (key: string) => string,
  setIsBottomSheetOpen: (isOpen: boolean) => void,
  setIsFinishQuoteBottomSheetOpen: (isOpen: boolean) => void,
) => {
  const titles = {
    [ScreenerVariant.Solar]: t("title-hero.solar-title"),
    [ScreenerVariant.BoilerInstallation]: t("title-hero.boiler-title"),
    [ScreenerVariant.HeatPump]: t("title-hero.heat-pump-title"),
    [ScreenerVariant.BoilerSubscription]: t("title-hero.boiler-subscription-title"),
    [ScreenerVariant.AirCondition]: t("title-hero.air-condition-title"),
  };

  const images = {
    [ScreenerVariant.Solar]: "https://boxt-bcms.imgix.net/R9X7gPOUTTWcHhxZzDO9",
    [ScreenerVariant.BoilerInstallation]: "https://boxt-bcms.imgix.net/jMBx2PJXThCX0u2fVpp0",
    [ScreenerVariant.HeatPump]: "https://boxt-bcms.imgix.net/q6TKvViCTSSMPzcfg9Ve",
    [ScreenerVariant.BoilerSubscription]: "https://boxt-bcms.imgix.net/A3THHdKnT8SIgdFtf0yc",
    [ScreenerVariant.AirCondition]: "https://boxt-bcms.imgix.net/o4UUWf4TyOZZsv9A9WaW",
  };

  const generatedTiles =
    existingScreeners?.map((screener, i) => {
      const { variant, url } = screener;
      return {
        item: {
          key: i,
          title: titles[variant],
          image: images[variant],
          link: url,
          meta: { id: i },
          slug: `finish-quote-${variant}`,
        },
      };
    }) || [];

  const newQuoteTile = {
    item: {
      title: t("title-hero.new-quote"),
      image: "https://boxt-bcms.imgix.net/lS53JZBxQyOLXnYD2pTw",
      meta: { id: generatedTiles.length + 1 },
      slug: "start-new-quote",
      onClick: () => {
        setIsFinishQuoteBottomSheetOpen(false);
        setIsBottomSheetOpen(true);
      },
    },
  };

  return [...generatedTiles, newQuoteTile];
};

export default useGenerateLandingProductTiles;
