import { Grid } from "@boxt/design-system";

import Image from "@Components/Image";
import {
  ImageWrapper,
  StyledGridCol,
} from "@Components/pages/common/LandingImageCollection/ImageCollectionColumn/ImageComponent/styles";
import type { ButterImageItem } from "@Components/pages/common/LandingImageCollection/types";

export enum ImageSize {
  sm,
  lg,
}

export type Props = {
  image: ButterImageItem;
  size: ImageSize;
};

const ImageComponent = ({ image: { width, height, quality, layout, objectFit, src, alt }, size }: Props) => (
  <Grid.Row>
    <StyledGridCol>
      <ImageWrapper $size={size}>
        <Image
          width={width}
          height={height}
          quality={quality}
          src={src}
          alt={alt}
          layout={layout}
          objectFit={objectFit}
        />
      </ImageWrapper>
    </StyledGridCol>
  </Grid.Row>
);

export default ImageComponent;
