import useTranslation from "next-translate/useTranslation";
import { PopUp } from "@boxt/design-system";

import Youtube from "@Components/Youtube";

export type Props = {
  onDismiss: () => void;
  videoId: string;
};

const TvAdvertPopup = ({ onDismiss, videoId }: Props) => {
  const { t } = useTranslation("common");

  return (
    <PopUp
      onDismiss={onDismiss}
      ariaLabel={{ "aria-label": t("tv-advert-popup-title") }}
      hasPadding={false}
      hasCloseButton
      contentSize="xlarge"
      data-testid="tv-advert-popup"
    >
      <Youtube
        id={videoId}
        options={{
          playerVars: {
            autoplay: 1,
          },
        }}
      />
    </PopUp>
  );
};

export default TvAdvertPopup;
