import Link from "next/link";
import styled, { css } from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, colors, Grid, H4, Icon, Paragraph, rem } from "@boxt/design-system";

import type { Theme } from "./types";

const getThemeColor = (theme: Theme): { background: string; border: string } => {
  switch (theme) {
    case "acid":
      return { background: colors.acidGreen.lighter, border: colors.acidGreen.darker };
    case "slate":
      return { background: colors.slate.lightest, border: colors.slate.lighter };
  }
};

export const StyledGrid = styled(Grid)`
  padding: 0;
  border-top: 1px solid ${colors.slate.lightest};

  ${breakpoint("md")} {
    margin-top: ${rem(48)};
  }
`;

export const GridContainer = styled.div`
  background-color: ${colors.white};
  border-radius: ${rem(16)};
  margin-bottom: ${rem(32)};
`;

export const Heading = styled(H4)`
  text-align: center;
  padding: ${rem(48)} ${rem(16)} ${rem(32)};

  ${breakpoint("md")} {
    padding: ${rem(32)} ${rem(16)} ${rem(52)};
  }
`;

export const ImageCol = styled.div<{ $theme: Theme }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(8)};

  ${breakpoint("md")} {
    padding: ${rem(16)};
    border-top-left-radius: ${rem(20)};
    border-top-right-radius: ${rem(20)};
    ${({ $theme }) => css`
      background: ${getThemeColor($theme).background};
      border: 2px solid ${getThemeColor($theme).border};
    `}
  }
`;

export const RowWrapper = styled(Grid.Row)`
  margin: 0 ${rem(8)};
  &:last-child > div:nth-child(2) {
    box-shadow: 0 8px 64px ${tinyColor(colors.acidGreen.normal).setAlpha(0.3).toString()};
  }

  &:nth-child(3) > div:nth-child(2) {
    border-top: 2px solid ${colors.acidGreen.darker};
    border-top-left-radius: ${rem(20)};
    border-top-right-radius: ${rem(20)};

    ${breakpoint("md")} {
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &:nth-child(3) > div:nth-child(3) {
    border-top: 2px solid ${colors.slate.lighter};
    border-top-left-radius: ${rem(20)};
    border-top-right-radius: ${rem(20)};

    ${breakpoint("md")} {
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  ${breakpoint("lg")} {
    gap: ${rem(16)};
  }
`;

export const Question = styled.div<{ $isLastRow: boolean; $hasTooltip: boolean }>`
  display: flex;
  align-items: center;
  padding-top: ${rem(8)};
  padding-bottom: ${rem(8)};
  gap: ${rem(8)};
  border-bottom: 1px dashed ${tinyColor(colors.slate.lighter).setAlpha(0.6).toString()};

  ${({ $isLastRow }) =>
    $isLastRow &&
    css`
      border-bottom: none;
    `}

  ${({ $hasTooltip }) =>
    $hasTooltip &&
    css`
      ~ div {
        align-items: flex-start;
        ${breakpoint("md")} {
          align-items: center;
        }
      }
    `}
`;

export const AnswerCol = styled.div<{ $theme: Theme; $isLastRow: boolean; $hasTooltip: boolean }>`
  min-height: ${rem(52)};
  display: flex;
  gap: ${rem(4)};
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: ${rem(8)};
  padding-bottom: ${rem(8)};
  border-bottom: 1px dashed ${tinyColor(colors.slate.lighter).setAlpha(0.6).toString()};

  > p {
    font-size: ${rem(14)};
  }

  ${({ $theme }) => css`
    background: ${getThemeColor($theme).background};
    border-left: 2px solid ${getThemeColor($theme).border};
    border-right: 2px solid ${getThemeColor($theme).border};
  `}

  ${({ $theme, $isLastRow }) =>
    $isLastRow &&
    css`
      border-bottom: 2px solid ${getThemeColor($theme).border};
      border-bottom-left-radius: ${rem(20)};
      border-bottom-right-radius: ${rem(20)};
    `}

  ${breakpoint("md")} {
    min-height: ${rem(64)};
    font-size: ${rem(16)};
    gap: ${rem(8)};

    > p {
      font-size: ${rem(16)};
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem(20)};
`;

export const AnswerIcon = styled(Icon)`
  font-size: 0;
`;

export const TooltipIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TooltipWrapper = styled.div`
  > div > div > p {
    ${breakpoint("sm")} {
      width: ${rem(64)};
    }
    ${breakpoint("lg")} {
      width: unset;
    }
  }
`;

export const BottomParagraph = styled(Paragraph)`
  margin: ${rem(32)} 0;
  padding: 0 ${rem(32)};
`;

export const BottomLink = styled(Link)`
  color: ${colors.coral.normal};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
