"use client";

import styled, { css } from "styled-components";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

import Video from "@Components/Video";

export const Container = styled.div`
  background-color: ${colors.slate.lightest};
  position: relative;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  overflow: hidden;

  ${breakpoint("md")} {
    min-height: ${rem(774)};
  }
`;

export const ContentWrapper = styled.div<{ $showComparisonTable?: boolean }>`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: ${rem(810)};
  margin: 0 auto;
  padding: 0 1.5rem;

  ${includeSpacing({
    mt: 5,
    mb: { sm: 5, md: 4 },
  })}

  ${({ $showComparisonTable }) =>
    $showComparisonTable &&
    css`
      max-width: ${rem(920)};
      padding: 0;
      ${includeSpacing({
        mt: 0,
        mb: { sm: 5, md: 4 },
      })}
    `}
`;

export const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyledVideo = styled(Video)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
