import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { isEmpty } from "lodash-es";
import { Grid, Spacing } from "@boxt/design-system";

import Emoji from "@Components/Emoji";
import Image from "@Components/Image";
import { ReactComponent as CircleUpChevron } from "@Images/icons/circle-chevron-round-up.svg";

import {
  BackgroundRow,
  BackgroundWrapper,
  Container,
  ContentWrapper,
  FlexTemplate,
  Header,
} from "../FinalSection/styles";

import { ButtonContainer, StyledButton, SvgWrapper } from "./styles";
import type { ButterBottomPostcodeCTAFields, UIBottomPostcodeCTAFields } from "./types";

type Props = {
  content: ButterBottomPostcodeCTAFields;
  i18nNamespace: string;
};

const BottomPostcodeWithCTA = ({ i18nNamespace, content }: Props) => {
  const { t } = useTranslation(i18nNamespace);

  const defaultContent: UIBottomPostcodeCTAFields = {
    header: (
      <Trans
        i18nKey={`${i18nNamespace}:postcode.title`}
        components={{
          bath: <Emoji type="bath" />,
        }}
      />
    ),
    ctaText: t("postcode.submit"),
    backgroundImage: "/_next-public/images/boxt.svg",
    ctaTheme: {
      theme: "coral",
      meta: {
        id: 123,
      },
      button_theme_slug: "coral",
      slug: "coral",
    },
  };

  const header = content?.header || defaultContent.header;
  const backgroundImage = content?.background_image || defaultContent.backgroundImage;
  const ctaText = content?.cta_text || defaultContent.ctaText;
  const ctaTheme = isEmpty(content?.cta_theme) ? defaultContent.ctaTheme.theme : content?.cta_theme?.theme;

  return (
    <Container id={content.id}>
      <Grid.Row as={BackgroundRow}>
        <Grid.Col as={BackgroundWrapper}>
          <SvgWrapper>
            <Image
              src={backgroundImage}
              alt="background-image"
              layout="fill"
              objectFit="cover"
              objectPosition="center"
            />
          </SvgWrapper>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row as={FlexTemplate}>
        <Grid.Col as={ContentWrapper}>
          <Spacing mb={{ sm: 4, md: 2, lg: 4 }}>
            <Header align="center">{header}</Header>
          </Spacing>
          <ButtonContainer>
            <StyledButton
              skin="primary"
              boxtTheme={ctaTheme}
              buttonSize="medium"
              titleText={ctaText}
              icon={{
                svg: <CircleUpChevron />,
                afix: "left",
                size: "medium",
              }}
            />
          </ButtonContainer>
        </Grid.Col>
      </Grid.Row>
    </Container>
  );
};

export default BottomPostcodeWithCTA;
