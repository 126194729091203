import useTranslation from "next-translate/useTranslation";
import { isEmpty } from "lodash-es";
import { Button, Grid, H3, Paragraph, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";
import ButterHTMLParsingContent from "@Components/pages/common/ButterHTMLParsingContent";

import LogoList from "./components/LogoList";
import type { Logo } from "./components/LogoList/LogoList";
import Tag from "./components/Tag";
import { StyledColumn, StyledRow, TagsContainer, Wrapper } from "./styles";
import type { Butter5050Fields } from "./types";

export type Props = {
  fields: Butter5050Fields;
  logoHeight?: number;
  logoWidth?: number;
};

const themeMapper = {
  dark: {
    ctaTheme: "dark",
    detailTheme: "dark",
    title: "dark",
    contentTheme: "dark",
  },
  light: {
    ctaTheme: "coal",
    title: "coal",
    detailTheme: "slateLight",
    contentTheme: "coalLighter",
  },
};

const defaultTheme = {
  ...themeMapper.light,
  ctaTheme: "jade",
};

const Landing5050 = ({ fields, logoHeight = 35, logoWidth = 35 }: Props) => {
  const { t } = useTranslation("list/index");

  const {
    android_logo: androidLogo,
    cta_link: ctaLink,
    description,
    detail,
    image_alignment: imageAlignment,
    ios_logo: iOSLogo,
    main_image: mainImage,
    tags,
    title,
    top_logos: topLogos,
    cta_text: ctaText,
    app_store_url: appStoreUrl,
    play_store_url: playStoreUrl,
    bg_color: bgColor,
    theme,
  } = fields;

  const mappedTopLogos = topLogos?.map((logo) => ({
    ...logo,
    logoHeight: logo?.height || logoHeight,
    logoWidth: logo?.width || logoWidth,
    altText: logo.alt_text,
  }));

  const getBottomLogos = () => {
    const bottomLogos: Logo[] = [];

    if (!isEmpty(androidLogo)) {
      bottomLogos.push({
        altText: t("landing-50-50.android-alt-text"),
        logoHeight: 42,
        logoWidth: 138,
        image: androidLogo,
        link: playStoreUrl,
      });
    }

    if (!isEmpty(iOSLogo)) {
      bottomLogos.push({
        altText: t("landing-50-50.ios-alt-text"),
        logoHeight: 42,
        logoWidth: 123,
        image: iOSLogo,
        link: appStoreUrl,
      });
    }

    return bottomLogos;
  };

  return (
    <Wrapper $bgColor={bgColor?.name}>
      <Spacing mv={5} mh={{ lg: 8, sm: 0, md: 0 }}>
        <Grid>
          <StyledRow $imageAlignment={imageAlignment}>
            <Grid.Col sm={4} md={4}>
              <Spacing
                mr={{ lg: imageAlignment === "left" ? 3 : 0, sm: 0, md: 0 }}
                ml={{ lg: imageAlignment === "left" ? 0 : 3, sm: 0, md: 0 }}
                data-testid="landing-50-50-main-image"
              >
                <Spacing mb={{ sm: 3, md: 0 }}>
                  <Image
                    priority
                    src={mainImage}
                    width={470}
                    height={470}
                    objectFit="contain"
                    alt=""
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  />
                </Spacing>
              </Spacing>
            </Grid.Col>
            <StyledColumn sm={4} md={4}>
              <Spacing
                mr={{ lg: imageAlignment === "left" ? 0 : 3, sm: 0, md: 0 }}
                ml={{ lg: imageAlignment === "left" ? 3 : 0, sm: 0, md: 0 }}
              >
                {!isEmpty(tags) && (
                  <Spacing mb={{ sm: 4, lg: 2 }}>
                    <TagsContainer>
                      {tags?.map(({ tag_label: tagLabel }) => <Tag key={tagLabel}>{tagLabel}</Tag>)}
                    </TagsContainer>
                  </Spacing>
                )}
                {!isEmpty(topLogos) && (
                  <Spacing mb={4}>
                    <LogoList logos={mappedTopLogos || []} dataTestId="landing-50-50-top-logo-list" />
                  </Spacing>
                )}
                {detail && (
                  <Spacing mb={2} data-testid="landing-50-50-detail">
                    <Paragraph
                      boxtTheme={themeMapper[theme.name]?.detailTheme || defaultTheme.detailTheme}
                      size="small"
                    >
                      {detail}
                    </Paragraph>
                  </Spacing>
                )}
                {title && (
                  <H3
                    data-testid="landing-50-50-title"
                    bottom={4}
                    weight="regular"
                    boxtTheme={themeMapper[theme.name]?.title || defaultTheme.title}
                  >
                    {title}
                  </H3>
                )}
                {description && (
                  <Spacing mb={{ sm: 0, lg: 1 }} data-testid="landing-50-50-description">
                    <ButterHTMLParsingContent
                      addContainerSpacing={false}
                      contentTheme={themeMapper[theme.name]?.contentTheme || defaultTheme.contentTheme}
                      fields={{ content: description }}
                      idAttribute="landing-5050-description"
                    />
                  </Spacing>
                )}
                {ctaLink && ctaText && (
                  <Spacing mb={{ sm: 2, md: 3 }}>
                    <Button
                      href={ctaLink}
                      boxtTheme={themeMapper[theme.name]?.ctaTheme || defaultTheme.ctaTheme}
                      // boxtTheme={"jade"}
                      skin="secondary"
                      data-testid="landing-50-50-cta"
                    >
                      {ctaText}
                    </Button>
                  </Spacing>
                )}
                {!isEmpty(getBottomLogos()) && (
                  <LogoList dataTestId="landing-50-50-bottom-logo-list" logos={getBottomLogos()} />
                )}
              </Spacing>
            </StyledColumn>
          </StyledRow>
        </Grid>
      </Spacing>
    </Wrapper>
  );
};

export default Landing5050;
