import { Grid } from "@boxt/design-system";

import type { ButterProductTileCollectionItem } from "@Collections/types/butter/collectionItem";
import CardsSlider from "@Components/pages/common/LandingProductTiles/CardsSlider";
import { MobileContainer, StyledGridCol, StyledGridRow } from "@Components/pages/common/LandingProductTiles/styles";
import type { ButterProductTile, ButterProductTilesFields } from "@Components/pages/common/LandingProductTiles/types";

import Card from "./Card";

export type Props = {
  idAttribute: string;
  fields: ButterProductTilesFields;
  isBottomSheet: boolean;
};

const LandingProductTiles = ({ idAttribute, isBottomSheet, fields: { tiles } }: Props) => {
  const cardItems = tiles.map(({ item }: ButterProductTile, i) => ({
    ...item,
    id: `${idAttribute}-${i}`,
  }));

  return (
    <>
      <StyledGridRow id={idAttribute} $isBottomSheet={isBottomSheet}>
        <Grid.Col as={StyledGridCol} $length={cardItems.length}>
          <CardsSlider cardItems={cardItems} idAttribute={idAttribute} isBottomSheet={isBottomSheet} />
        </Grid.Col>
      </StyledGridRow>
      {isBottomSheet && (
        <MobileContainer data-testid="mobile-container">
          {cardItems.map((cardItem: ButterProductTileCollectionItem, index: number) => (
            <Card
              key={`${idAttribute}-${index}`}
              cardConfig={cardItem}
              cardIndex={index}
              lastCard={index === cardItems.length - 1}
              firstCard={index === 0}
              isBottomSheet={isBottomSheet}
            />
          ))}
        </MobileContainer>
      )}
    </>
  );
};

export default LandingProductTiles;
