import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { isEmpty } from "lodash-es";
import { parseCookies } from "nookies";
import useSWR from "swr";
import { BottomSheet, Hero, Paragraph, Spacing } from "@boxt/design-system";

import useGenerateFinishQuoteTiles from "@Helpers/generateFinishQuoteTiles";
import { API_URLS, SCREENING_URLS } from "@Collections/routes";
import { fetcher } from "@DataAccess/local";
import VisibilitySensor from "@Hoc/VisibilitySensor";
import { SCREENER_COOKIE_KEY } from "@Pages/api/_next/screeners/_constants";
import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

import LandingProductTiles from "../LandingProductTiles";
import type { ButterProductTile } from "../LandingProductTiles/types";

import BottomBar from "./components/BottomBar";
import Buttons from "./components/Buttons";
import { TitleContainer } from "./styles";
import type { ButterTitleFields } from "./types";

export type Props = {
  fields: ButterTitleFields;
  idAttribute: string;
};

const i18nNamespace = "list/index";

const ButterTitleHero = ({ fields, idAttribute }: Props) => {
  const [isBottomBarOpen, setIsBottomBarOpen] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [isFinishQuoteBottomSheetOpen, setIsFinishQuoteBottomSheetOpen] = useState(false);

  const { t } = useTranslation(i18nNamespace);

  const router = useRouter();

  const {
    button: defaultButton,
    alignment,
    title_text: titleText,
    sub_heading: subHeading,
    hero_version: heroVersion,
    bottom_sheet_button: bottomSheetButton,
    bottom_sheet_header: bottomSheetHeader,
    tiles = [],
    include_finish_quote_button: displayFinishQuoteButton,
  } = fields;

  const screenersToCheck = [ScreenerVariant.BoilerInstallation, ScreenerVariant.Solar];

  const { data, error, isValidating } = useSWR(
    { url: API_URLS.SCREENING_STATUS },
    ({ url }: { url: string }) =>
      Promise.all(screenersToCheck.map((variant) => fetcher(url, { screenerVariant: variant }))),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const isLoading = (!data && !error) || isValidating;

  const existingScreeners =
    (data
      ?.map((screener) => {
        if (screener?.isScreenerComplete) {
          const cookies = parseCookies();
          const screeningId = cookies[`${SCREENER_COOKIE_KEY}:${screener.screenerVariant}`];
          return {
            variant: screener?.screenerVariant,
            url: SCREENING_URLS[screener?.screenerVariant]?.complete.replace("[screeningId]", screeningId),
          };
        } else if (screener?.isScreenerStarted) {
          return {
            variant: screener?.screenerVariant,
            url: SCREENING_URLS[screener?.screenerVariant]?.start,
          };
        }
        return null;
      })
      .filter(Boolean) as { variant: ScreenerVariant; url: string }[]) || [];

  const handleFinishQuoteClick = () => {
    if (Number(existingScreeners?.length) > 1) {
      setIsFinishQuoteBottomSheetOpen(true);
    } else if (existingScreeners?.length === 1) {
      router?.push({
        pathname: existingScreeners[0]?.url,
      });
    }
  };

  const finishQuoteTiles = useGenerateFinishQuoteTiles(
    existingScreeners,
    t,
    setIsBottomSheetOpen,
    setIsFinishQuoteBottomSheetOpen,
  ) as ButterProductTile[];

  const handleTitleHeroEnter = () => {
    if (isBottomBarOpen) {
      setIsBottomBarOpen(false);
    }
  };

  const handleTitleHeroLeave = () => {
    if (!isBottomBarOpen) {
      setIsBottomBarOpen(true);
    }
  };

  const hasFinishQuoteButton = displayFinishQuoteButton && !isEmpty(existingScreeners);
  const hasDefaultButton = !isEmpty(defaultButton) && heroVersion === "no-bottom-sheet";
  const hasBottomSheetButton = !isEmpty(bottomSheetButton) && heroVersion === "new-quote-bottom-sheet";

  const hasButtons = hasFinishQuoteButton || hasDefaultButton || hasBottomSheetButton;

  return (
    <>
      <TitleContainer>
        {titleText.map(({ text, color }, i) => (
          <Hero
            align={alignment || "center"}
            weight="regular"
            key={`${idAttribute}-${i}`}
            boxtTheme={color.name}
            data-testid="hero-title"
          >
            {text}
          </Hero>
        ))}
        {subHeading && (
          <Spacing mt={2}>
            <Paragraph data-testid="sub-heading">{subHeading}</Paragraph>
          </Spacing>
        )}
        {isLoading ? (
          <Spacing mt={5}>
            <Skeleton width="20%" containerTestId="skeleton-loader" />
          </Spacing>
        ) : (
          <Spacing mt={5}>
            <VisibilitySensor onLeave={handleTitleHeroLeave} onEnter={handleTitleHeroEnter}>
              <Buttons
                bottomSheetButton={bottomSheetButton}
                setIsBottomSheetOpen={setIsBottomSheetOpen}
                defaultButton={defaultButton}
                handleFinishQuoteClick={handleFinishQuoteClick}
                i18nNamespace={i18nNamespace}
                hasFinishQuoteButton={hasFinishQuoteButton}
                hasDefaultButton={hasDefaultButton}
                hasBottomSheetButton={hasBottomSheetButton}
              />
            </VisibilitySensor>
          </Spacing>
        )}
      </TitleContainer>
      <BottomSheet
        open={isBottomSheetOpen}
        setIsOpen={setIsBottomSheetOpen}
        headerTitle={bottomSheetHeader}
        maxWidth={1200}
        blocking
      >
        <LandingProductTiles idAttribute={"product-tiles-bottom-sheet"} fields={{ tiles }} isBottomSheet />
      </BottomSheet>
      <BottomSheet
        open={isFinishQuoteBottomSheetOpen}
        setIsOpen={setIsFinishQuoteBottomSheetOpen}
        headerTitle={t("title-hero.finish-your-quote")}
        maxWidth={1200}
        blocking
      >
        <LandingProductTiles
          idAttribute={"product-tiles-bottom-sheet"}
          fields={{ tiles: finishQuoteTiles }}
          isBottomSheet
        />
      </BottomSheet>
      {hasButtons && (
        <BottomBar
          isBottomBarOpen={isBottomBarOpen}
          bottomSheetButton={bottomSheetButton}
          setIsBottomSheetOpen={setIsBottomSheetOpen}
          defaultButton={defaultButton}
          handleFinishQuoteClick={handleFinishQuoteClick}
          i18nNamespace={i18nNamespace}
          hasFinishQuoteButton={hasFinishQuoteButton}
          hasDefaultButton={hasDefaultButton}
          hasBottomSheetButton={hasBottomSheetButton}
        />
      )}
    </>
  );
};

export default ButterTitleHero;
