import useTranslation from "next-translate/useTranslation";
import { isEmpty } from "lodash-es";
import { breakpoints, H3, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";
import Slider from "@Components/pages/common/Slider";

import { Container, SlideWrapper, WrapperStyle } from "./styles";
import type { ButterPartnersSliderFields, Partner } from "./types";

type Props = {
  fields: ButterPartnersSliderFields;
  keyName: string;
};

const defaultValues = {
  delay: 6000,
  opacity: "0.5",
};

const LandingPartnersSlider = ({
  fields: { partners: butterSlides, title, opacity, id, animation_delay },
  keyName,
}: Props) => {
  const { t: tCommon } = useTranslation("common");

  const slides: Partner[] = butterSlides.length
    ? butterSlides
    : tCommon("partners.slides", {}, { returnObjects: true });

  return (
    <Container mv={2} id={id}>
      <Spacing mb={4}>
        <H3 align="center">{isEmpty(title) ? tCommon("partners.title") : title}</H3>
      </Spacing>
      <Slider
        duration={typeof animation_delay === "string" ? defaultValues.delay : animation_delay}
        includeSpacing={false}
        wrapperStyle={WrapperStyle}
        showDotsController={false}
        sliderOptions={{
          loop: true,
          breakpoints: {
            [`(min-width: ${breakpoints.sm.width})`]: {
              slides: {
                origin: "center",
                perView: 4,
              },
            },
            [`(min-width: ${breakpoints.md.width})`]: {
              slides: {
                perView: 5,
              },
            },
          },
        }}
      >
        {slides.map((slide, i) => (
          <SlideWrapper
            $opacity={isEmpty(opacity) ? defaultValues.opacity : opacity}
            className={`keen-slider__slide number-slide-${i}`}
            key={`${keyName}-${i}`}
          >
            <Image src={slide.image} alt={slide.image_alt} width={163} height={80} />
          </SlideWrapper>
        ))}
      </Slider>
    </Container>
  );
};

export default LandingPartnersSlider;
